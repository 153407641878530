module.exports = {
  'kurzy - boxTitle - MIU 1 až 3': 'MIU 1 až 3',
  'Rychlé hledání FIE/LPAD škol či lektorů': 'Rýchle vyhľadávanie škôl alebo lektorov',
  'Vše': 'Vše',
  'najít': 'nájsť',
  'NAPIŠTE LOKALITU': 'NAPÍŠTE LOKALITU',
  'Nic nenalezeno. Zkuste oddálit mapu a změnit filtrování dle typu výše.': 'Nic nenalezeno. Zkuste oddálit mapu a změnit filtrování dle typu výše.',
  'kurzy - tab - MIU Elementární (Basic)': 'MIU Elementárna (Basic)',
  'kurzy - tab - MIU 1 až 3 (Standard)': 'MIU 1 až 3 (Standard)',
  'Vyberte si typ kurzu / druh akce': 'Vyberte si typ kurzu / druh akce',
  'Typ kurzu:': 'Typ kurzu:',
  'kurzy - tab - Ochutnávky a semináře': 'Ochutnávky a semináře',
  'kurzy - tab - Konference a další akce': 'Konferencie a ďalšie akcie',
  'kurzy - boxTitle - Ochutnávky a semináře': 'Ochutnávky a semináře',
  'kurzy - boxTitle - Konference a další akce': 'Konferencie a ďalšie akcie',
  'kurzy - boxTitle - MIU Elementární': 'MIU Elementárna',
  'více informací': 'viac informácií',
  'Kurzy <b>řady Standard</b>  seznámí účastníky s teoretickými základy metody vycházející z Feuersteinova instrumentálního obohacování – od úvodního kurzu <b>Standard 1</b> po navazující (volitelné) <b>Standard 2</b> nebo <b>Standard 3</b>.': 'Kurzy úrovně <b>MIU 1 až 3</b> (dříve Standard) seznámí účastníky s teoretickými základy Metody instrumentálního uvědomování (MIU), se zprostředkováním a s praktickým využitím výukových materiálů MIU (instrumentů). Kurzy jsou určeny pro budoucí lektory MIU pracující s klienty ve věku od 7–8 let výše. Na úvodní kurz MIU 1 navazují kurzy MIU 2 a MIU 3, absolventům dřívějších kurzů nabízíme kurzy MIU pro změnu. — Všechny naše kurzy jsou plně prezenční.',
  'Kurzy řady <b>Basic</b> přinesou účastníkům variantu metody pro práci s dětmi předškolního věku nebo s jedinci se zásadnějšími problémy, vycházející z Feuersteinova instrumentálního obohacování.': 'Kurzy  <b>MIU Elementární</b> (dříve Basic) jsou určeny pro budoucí lektory MIU pracující s dětmi přibližně ve věku do 7 až 8 let, případně s klienty s vážnějšími potížemi. Kurzy prohloubí znalosti teoretických základů Metody instrumentálního uvědomování a dovednosti při zprostředkování. Kurzy nabízíme ve variantách MIU E8 (osmidenní) nebo MIU E5 (pětidenní); na pětidenní kurz lze navázat doplňujícím kurzem MIU E3 (třídenní). Kurz MIU kombinovaný je možným vstupem do práce s metodou. — Všechny kurzy jsou plně prezenční.',
  'Kurz Dynamického vyšetření seznámí účastníky s teoretickými základy i s praktickými postupy a nástroji pro dynamické hodnocení učebního potenciálu.': 'Kurz Dynamického vyšetření seznámí účastníky s teoretickými základy i s praktickými postupy a nástroji pro dynamické hodnocení učebního potenciálu.',
  'Naše jednodenní semináře umožní účastníkům blíže se seznámit s metodami zážitkovou formou (ochutnávky). Nabídne tak absolventům kurzů možnost upevnit si zásady práce s metodou instrumentálního obohacování a uvědomování nebo s metodou LPAD a najít odpovědi na otázky z praxe (inspirační semináře).': 'První seznámení s Metodou instrumentálního uvědomování zážitkovou formou umožňují jednodenní <b>ochutnávkové semináře</b>. Pro absolventy našich vzdělávacích kurzů MIU připravujeme <b>inspirační semináře</b> ke sdílení zkušeností a prohloubení práce s MIU s ohledem na potřeby konkrétních klientů. — Semináře i ochutnávky pořádáme v plně prezenční formě.',
  '<b>Naše konference</b> pořádáme s cílem představit účastníkům různá místa a prostředí, do kterých bylo začleněno <b>MIU</b> a dynamická diagnostika učebního potenciálu jako pevná součást každodenního života.': 'ATC metod pro osobní rozvoj a teraz aj Myslenie pre život pravidelne organizujú <b>konferencie o Metóde inštrumentálneho uvedomovania</b>, ktoré sú príležitosťou pre podrobnejšie nahliadnutie na MIU z rôznych uhlov pohľadu a vytvárajú priestor pre zdieľanie skúseností z miest a prostredí, kde sa MIU v Českej republike a na Slovensku využíva. Konferencie sú príležitosťou na inšpiratívne stretnutie pre všetkých, ktorí sa venujú MIU alebo majú záujem ju spoznať.',
  'Lektoři a školy': 'Spolupracujeme',}