import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import HintTooltip from './HintTooltip'
import Error from './Error'
import Label from './Label'
import {
  addResponsivity,
  up,
  down,
  between,
  addFont,
  colors,
} from '../../lib/styles'
import { Col, Row, Text, Title, Gap, Link, VH, Button } from '..'

export const StyledInput = styled.input`
  ${({
    theme: { colors },
    error,
    disabled,
    lineHeight = '44px',
    placeholderFontWeight = 300,
    padding = '0 10px',
  }) => css`
    background: white;
    /* box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.25); */
    border-radius: 0;
    padding: ${padding};
    border: solid 2px #c7c7c7;
    outline: none;
    line-height: ${lineHeight};
    font-size: 15px;
    font-weight: 400;

    &::placeholder {
      color: #bdbdbd;
      font-weight: ${placeholderFontWeight};
      font-size: 15px;
    }

    ${up('mobile')} {
      font-size: 20px;
    }

    ${error &&
      css`
        border-color: ${colors.red};
      `}

    ${disabled &&
      css`
        background: ${colors.lightGrey};
      `}
  `}
`

const Input = ({
  field,
  form: { errors, setFieldTouched, setFieldValue, touched },
  meta,
  label,
  hint,
  asTextArea,
  ...props
}) => (
  <Col>
    <Row>
      <Label htmlFor={props.id || field.name}>{label}</Label>
      <HintTooltip hint={hint} />
    </Row>
    <StyledInput
      {...field}
      {...props}
      as={asTextArea && 'textarea'}
      error={touched[field.name] && errors[field.name]}
    />
    <Gap gap="4px" />
    <Error name={field.name} />
    <Gap gap="16px" />
  </Col>
)

export default Input
