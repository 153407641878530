import React from 'react'
import styled, { css } from 'styled-components'
import { Text } from '.'
import { up, addResponsivity } from '../lib/styles'

// default values belong to Page title
const Title = styled.span`
  ${({
    theme: { colors, fonts },
    fontSize = '52px',
    fontWeight = 100,
    lineHeight,
    mobileFontSize,
    mobileLineHeight,
    maxWidth,
    mobileMaxWidth,
    textAlign,
    alignSelf,
    mobileTextAlign,
    display = 'block',
    bp = 'mobile', // can be px or bp name
    margin,
    color = 'text',
    letterSpacing,
    mobileLetterSpacing,
    upperCase,
  }) => css`
    font-weight: ${fontWeight};
    font-family: ${fonts.noto};
    max-width: ${mobileMaxWidth || maxWidth};
    align-self: ${alignSelf};
    display: ${display};
    color: ${colors[color] || color};
    text-transform: ${upperCase && 'uppercase'};

    font-size: ${mobileFontSize || fontSize};
    line-height: ${mobileLineHeight || lineHeight};
    text-align: ${mobileTextAlign || textAlign};
    letter-spacing: ${mobileLetterSpacing || letterSpacing};

    /* reset */
    margin: 0;
    margin: ${margin};
    padding: 0;

    ${up(bp)} {
      font-size: ${fontSize};
      line-height: ${lineHeight};
      text-align: ${textAlign};
      letter-spacing: ${letterSpacing};
      max-width: ${maxWidth};
    }

    ${addResponsivity({ displayAs: display })}
  `}
`

// define basic styles used on page
// PROTIP: preddefinovane mobile / desktop velikosti, lze ovlivnit nastavenim vlastniho BP

// all pages h1 title
Title.Page = props => (
  <Title
    fontSize="70px"
    lineHeight="85px"
    mobileFontSize="32px"
    mobileLineHeight="38px"
    color="white"
    letterSpacing="2.8px"
    as={props.innerAs || 'h1'}
    {...props}
  />
)

// usually h2 title, bigger version
// eg. hp sections, blog title...
// !! auto-responsive!
Title.Section = props => (
  <Title
    fontSize="52px"
    lineHeight="62px"
    mobileFontSize="25px"
    mobileLineHeight="34px"
    as={props.innerAs || 'h2'}
    {...props}
  />
)
Title.SubSection = props => (
  <Title
    fontSize="33px"
    lineHeight="33px"
    letterSpacing="1.65px"
    mobileFontSize="25px"
    mobileLineHeight="30px"
    as={props.innerAs || 'h3'}
    {...props}
  />
)

Title.Form = props => (
  <Title
    fontSize="33px"
    lineHeight="40px"
    mobileFontSize="25px"
    mobileLineHeight="34px"
    {...props}
  />
)

// the small orange / white uppercase mini title
Title.Mini = props => (
  <Title
    fontSize="15px"
    lineHeight="30px"
    mobileFontSize="12px"
    mobileLineHeight="18px"
    letterSpacing="4px"
    fontWeight="400"
    upperCase
    {...props}
  />
)
Title.MiniOrange = props => <Title.Mini color="orange" {...props} />
Title.MiniWhite = props =>
  <Title.Mini color="white" fontWeight="700" {...props} />

Title.Footer = props => (
  <Title
    fontSize="33px"
    lineHeight="40px"
    mobileFontSize="22px"
    mobileLineHeight="20px"
    as="h4"
    color="orange"
    letterSpacing="2.5px"
    {...props}
  />
)

export default Title
