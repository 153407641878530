import React, { useEffect, useRef, useState } from 'react'
import YouTube from 'react-youtube'
import styled, { css } from 'styled-components'
import { differenceInMinutes } from 'date-fns'
import indicatorPath from '../data/images/loading-indicator.gif'

import indicatorBase from './IndicatorBase64'
import { up } from '../lib/styles'

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000000;
  display: none;

  ${up(500)} {
    display: block;
  }
`

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10002;
  top: 0;
  left: 0;

  ${({ shown, fadedOut }) => css`
    opacity: ${shown ? 1 : 0};
    transition: 1s;
  `}

  & .youtube-container {
    width: 100vw;
    height: 100vh;

    iframe {
      top: -60px;
      width: 100vw;
      height: 100vh;
      height: calc(100vh + 120px);
      position: absolute;
    }
  }
`
const Overlay = styled.div`
  ${({ white, black, below, fadedOut }) => css`
    background: ${white ? 'white' : black ? 'black' : 'transparent'};
    z-index: ${below ? 10000 : 10004};
    opacity: ${fadedOut ? 0 : 1};
  `}
  transition: 4s;
  position: absolute;
  width: 100vw;
  height: 100vh;

  top: 0;
  left: 0;
`
const Indicator = styled.img`
  display: block;
  ${({ hidden }) => css`
    opacity: ${hidden ? 0 : 1};
  `}
  transition: 1s;
  position: absolute;

  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  max-width: 100px;
  max-height: 100px;
`
const PlayWrapper = styled.div`
  display: block;
  ${({ hidden }) => css`
    opacity: ${hidden ? 0 : 1};
  `}
  transition: 1s;
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
`

function IntroVideo(props) {
  const [shown, setShown] = useState(true)
  const [isReady, setIsReady] = useState(false)
  const [ytShown, setYTShown] = useState(true)
  const [fadingOut, setFadingOut] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const videoRef = useRef(null)
  const intervalRef = useRef(null)

  useEffect(() => {
    const lastPlayAt = localStorage.getItem('lastPlayAt')

    const diff = differenceInMinutes(new Date(lastPlayAt), new Date())

    // less then day? hide it
    if (lastPlayAt && Math.abs(diff) < 60 * 24) {
      setShown(false)
    }
  }, [])

  const opts = {
    height: '600',
    width: '1000',
    playerVars: {
      autoplay: 1,
      modestbranding: 1,
      controls: 0,
      rel: 0,
      mute: 1,
      muted: 1,
    },
  }

  function onEnd() {
    clearInterval(intervalRef.current)
    setFadingOut(true)
    setYTShown(false)
    setTimeout(() => {
      setShown(false)
      console.log('final hide')
    }, 5000)
    const lastPlayAt = localStorage.setItem(
      'lastPlayAt',
      new Date().toISOString(),
    )
  }

  function onPlay() {
    setIsPlaying(true)

    intervalRef.current = setInterval(function () {
      const duration = videoRef.current.getDuration()
      const current = videoRef.current.getCurrentTime()

      console.log('interval')
      if (duration - current <= 0.25) {
        videoRef.current.pauseVideo()
        clearInterval(intervalRef.current)
        onEnd()
      }
    }, 100) // every second
  }

  function onReady({ target }) {
    videoRef.current = target
    setIsReady(true)
  }

  return (
    shown && (
      <Wrapper>
        <Overlay white={!isPlaying} onClick={onEnd}>
          <Indicator src={indicatorBase} hidden={isReady} />
          <PlayWrapper
            hidden={!isReady || isPlaying}
            onClick={event => {
              event.stopPropagation()
              if (videoRef.current) {
                onPlay()
                videoRef.current.playVideo()
              } else {
                setTimeout(() => {
                  onPlay()
                  videoRef.current.playVideo()
                }, 500)
              }
            }}
          >
            Přehrát video
          </PlayWrapper>
        </Overlay>
        <Container shown={ytShown} fadedOut={fadingOut}>
          <YouTube
            containerClassName="youtube-container"
            videoId="3PdHzxy6C1U"
            opts={opts}
            onEnd={onEnd}
            onPlay={onPlay}
            onReady={onReady}
          />
        </Container>
        {/* <Overlay white={!fadingOut} below /> */}
        <Overlay white below fadedOut={fadingOut} />
      </Wrapper>
    )
  )
}

export default IntroVideo
