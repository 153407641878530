import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { up, addResponsivity } from '../lib/styles'

const Line = styled.div`
  ${({ theme: { colors }, color = '#d5d9e2', isVertical = false }) => css`
    ${addResponsivity()}
    height: 1px;
    background-color: ${color};
    width: 100%;
    ${isVertical &&
      css`
        width: 1px;
        height: auto;
        flex-shrink: 0;
        align-self: stretch;
      `}
  `}
`

export default Line
