// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-collaborators-headless-js": () => import("./../../../src/pages/collaborators-headless.js" /* webpackChunkName: "component---src-pages-collaborators-headless-js" */),
  "component---src-pages-collaborators-js": () => import("./../../../src/pages/collaborators.js" /* webpackChunkName: "component---src-pages-collaborators-js" */),
  "component---src-pages-courses-headless-js": () => import("./../../../src/pages/courses-headless.js" /* webpackChunkName: "component---src-pages-courses-headless-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-journey-js": () => import("./../../../src/pages/journey.js" /* webpackChunkName: "component---src-pages-journey-js" */),
  "component---src-pages-methods-approach-js": () => import("./../../../src/pages/methods/approach.js" /* webpackChunkName: "component---src-pages-methods-approach-js" */),
  "component---src-pages-methods-enrichment-js": () => import("./../../../src/pages/methods/enrichment.js" /* webpackChunkName: "component---src-pages-methods-enrichment-js" */),
  "component---src-pages-methods-feuerstein-js": () => import("./../../../src/pages/methods/feuerstein.js" /* webpackChunkName: "component---src-pages-methods-feuerstein-js" */),
  "component---src-pages-methods-js": () => import("./../../../src/pages/methods.js" /* webpackChunkName: "component---src-pages-methods-js" */),
  "component---src-pages-methods-lpad-js": () => import("./../../../src/pages/methods/lpad.js" /* webpackChunkName: "component---src-pages-methods-lpad-js" */),
  "component---src-pages-methods-miu-js": () => import("./../../../src/pages/methods/miu.js" /* webpackChunkName: "component---src-pages-methods-miu-js" */),
  "component---src-pages-methods-theory-js": () => import("./../../../src/pages/methods/theory.js" /* webpackChunkName: "component---src-pages-methods-theory-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-course-template-js": () => import("./../../../src/templates/CourseTemplate.js" /* webpackChunkName: "component---src-templates-course-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/PostTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-project-template-js": () => import("./../../../src/templates/ProjectTemplate.js" /* webpackChunkName: "component---src-templates-project-template-js" */)
}

