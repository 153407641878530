// external imports
import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../../components/SEO'
import { links } from '../../links'
import { Text, MethodPageLayout, Button, Gap } from '../../components'
import T from '../../lib/intl/T'

class Lpad extends React.Component {
  componentDidMount() {}

  render() {
    const {
      location: { pathname },
      data: { image1 },
    } = this.props
    const images = [image1]

    // const { language, t } = this.props.intl

    // console.log('this.props', this.props)

    return (
      <>
        <SEO
          title="Metoda instrumentálního uvědomování (MIU)"
          pathname={pathname}
        />
        <MethodPageLayout>
          <MethodPageLayout.Title>
            <T>Metoda instrumentálního uvědomování (MIU)</T>
          </MethodPageLayout.Title>
          <MethodPageLayout.TitleGap />
          <MethodPageLayout.Content images={images}>
            <Text.Wide>
              <T raw>miu - content</T>
            </Text.Wide>
            <Gap gap="40px" />
            <Button.SecondaryOrange
              link={{ pageKey: links.METHODS }}
              iconLeft="arrowLeft"
            >
              Zpátky na rozcestník
            </Button.SecondaryOrange>
          </MethodPageLayout.Content>
        </MethodPageLayout>
      </>
    )
  }
}

export default Lpad

export const pageQuery = graphql`
  query {
    image1: file(relativePath: { eq: "images/methods/methods5.png" }) {
      image: childImageSharp {
        fluid(maxWidth: 452, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
