// external imports
import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'

// helpers
import { up, addResponsivity } from '../lib/styles'
import { extractNodes, extractNodesFromData } from '../lib/helpers'
import injectIntl from '../lib/intl/injectIntl'
import withExtraction from '../lib/withExtraction'

// components
import SEO from '../components/SEO'
import {
  Title,
  Gap,
  Row,
  Col,
  Link,
  Text,
  RH,
  Button,
  HeaderBar,
  Testimonials,
} from '../components'
import { Page, PageContent } from '../components/Page'
import CoursesBox from '../components/CoursesBox'
import CourseDetailBox from '../components/CourseDetailBox'
import { getCategoriesKeys } from '../courses'
import headerBarImageSrc from '../data/images/courses-top.png'
import { isCs, isSk } from '../lib/appVersions'
import T from '../lib/intl/T'
import { t } from '../lib/intl'

const groupCourseTypesByCategory = (allCourseTypes = []) => {
  const categories = {}
  allCourseTypes.forEach(type => {
    if (!categories[type.category]) {
      categories[type.category] = []
    }
    categories[type.category].push(type)
  })
  return categories
}

const categoriesKeys = getCategoriesKeys()

const TopImage = styled.img`
  padding: 53px 0 56px 0;
`

class Courses extends React.Component {
  state = {
    activeCategoryKey: categoriesKeys[0],
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search)
    const cat = urlParams.get('active')
    if (categoriesKeys.includes(cat)) {
      this.setActiveCategory(cat)
    }
  }

  setActiveCategory = activeCategoryKey => {
    this.setState({ activeCategoryKey })
  }

  render() {
    const { activeCategoryKey } = this.state
    const {
      location: { pathname },
      intl: { language, headless },
      data: { allCourseTypes },
    } = this.props

    // console.log('allCourseTypesallCourseTypesallCourseTypes', allCourseTypes)
    // console.log('this.props', this.props)
    // const headless = search === '?headless'

    const coursesTypesByCategories = groupCourseTypesByCategory(allCourseTypes)

    const activeCategory = coursesTypesByCategories[activeCategoryKey]

    if (headless) {
      return (
        <>
          <CoursesBox
            coursesTypesByCategories={coursesTypesByCategories}
            setActiveCategory={this.setActiveCategory}
            activeCategoryKey={activeCategoryKey}
          />

          {activeCategoryKey &&
            activeCategory &&
            activeCategory.map((courseType, index) => (
              <>
                <Gap gap="60px" />
                <Gap gap="0" id={Link.anchors.COURSE_DETAILS} />
                <CourseDetailBox
                  courseType={courseType}
                  key={courseType.id}
                  setActiveCategory={this.setActiveCategory}
                />
              </>
            ))}
        </>
      )
    }

    return (
      <>
        <SEO title={t('Kurzy a akce')} pathname={pathname} />

        <HeaderBar>
          <HeaderBar.Content>
            <HeaderBar.Title>
              <T>Kurzy a akce</T>
            </HeaderBar.Title>
            <HeaderBar.SubTitle>
              <T>Nabídka kurzů, seminářů a konferencí</T>
            </HeaderBar.SubTitle>
          </HeaderBar.Content>
          {/* <HeaderBar.ImageWrapper mobileMaxWidth="450px">
            <img src={headerBarImageSrc} alt="" />
          </HeaderBar.ImageWrapper> */}
        </HeaderBar>
        <Page grey>
          <PageContent>
            <Gap gap="90px" />

            {isCs && (
              <>
                <CoursesBox
                  coursesTypesByCategories={coursesTypesByCategories}
                  setActiveCategory={this.setActiveCategory}
                  activeCategoryKey={activeCategoryKey}
                />

                {activeCategoryKey &&
                  activeCategory &&
                  activeCategory.map((courseType, index) => (
                    <>
                      <Gap gap="60px" />
                      <Gap gap="0" id={Link.anchors.COURSE_DETAILS} />
                      <CourseDetailBox
                        courseType={courseType}
                        key={courseType.id}
                        setActiveCategory={this.setActiveCategory}
                      />
                    </>
                  ))}
              </>
            )}

            {isSk && (
              <>
                <iframe
                  src="https://a-t-c.cz/courses-headless?headless"
                  width="100%"
                  height="3000px"
                  frameBorder={0}
                  title="Nabízené kurzy"
                  id="courses-headless-box"
                />
              </>
            )}

            <Gap gap="176px" mobileGap="44px" />
          </PageContent>
        </Page>
        <Gap gap="150px" mobileGap="33px" />
        <Page>
          <PageContent>
            <Testimonials />
          </PageContent>
        </Page>
        <Gap gap="194px" mobileGap="63px" />
      </>
    )
  }
}

// {Object.keys(coursesTypesByCategories).map(categoryKey => (
//   <div>
//     categoryKey: {categoryKey} <br />
//     {/* popisky kategorii dat nekam do souboru */}
//     {coursesTypesByCategories[categoryKey].map(type => (
//       <div>
//         type : {type.name} {type.longName}
//         {type.courseTerms.map(t => t.fromToDesc)}
//       </div>
//     ))}
//   </div>
// ))}

export default injectIntl(withExtraction(Courses))

export const pageQuery = graphql`
  query {
    ks {
      allCourseTypes(sortBy: sortOrder_ASC) {
        ...CourseTypeFragment
        ...CourseTypeNestedFragment
      }
    }
  }
`


// this fn is run always, both domains, even outside of scope of this page
if (typeof window !== 'undefined') {
  window.addEventListener('message', event => {
    console.log('incoming message', event)
    // Verify the origin of the message
    if (
      ![
        'https://a-t-c.cz',
        'https://atc-sk.wx.virtii.com',
        'http://127.0.0.1:8000',
        'http://localhost:8000',
      ].includes(event.origin)
    ) {
      return // Ignore messages from unexpected origins
    }

    const iframe = document.getElementById('courses-headless-box')
    if (iframe && event.data.height && event.data.coursesHeadless) {
      console.log('courses headless event received', event.data)
      iframe.style.height = `${event.data.height}px`
    }

    const iframe2 = document.getElementById('collaborators-headless-box')
    if (iframe2 && event.data.height && event.data.coursesHeadless) {
      console.log('courses headless event received', event.data)
      iframe2.style.height = `${event.data.height}px`
    }
  })
}
