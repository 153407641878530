/* eslint-disable react/no-danger */
import Select from 'react-select'
import { useStaticQuery, graphql } from 'gatsby'
import React, { useState } from 'react'
import moment from 'moment'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Text from './Text'
import Row from './Row'
import Col from './Col'
import Gap from './Gap'
import Link from './Link'
import Title from './Title'
import Button from './Button'
import { Line, RH } from '.'
import { Page, PageContent } from './Page'
import T from '../lib/intl/T'
import { up, addPagePadding } from '../lib/styles'
import pagesConfig from '../../pagesConfig'
import injectIntl from '../lib/intl/injectIntl'
import { categories, getCategoriesKeys } from '../courses'

const Wrapper = styled.div`
  ${({ theme: { colors } }) => css`
    box-shadow: 2px 3px 81px rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
  `}
`

const StyledRow = styled(Row)`
  align-items: center;
  ${up('mobile')} {
    align-items: flex-start;
  }
`

export const Grid = styled.div`
  ${({
    theme: { colors },
    padding = '88px 56px 96px',
    mobilePadding = '8px 18px',
  }) => css`
    padding: ${mobilePadding};
    display: column;

    ${up('menu')} {
      padding: ${padding};
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
  `}
`

export const GridItem = styled.div`
  ${({ theme: { colors }, col, row, withoutBorder }) => css`
    padding-top: 32px;
    padding-bottom: 28px;

    ${!withoutBorder &&
      css`
        border-top: solid thin #d5d9e2;
      `}

    ${up('menu')} {
      grid-column: ${col};
      grid-row: ${row};
      padding-right: 56px;
      padding-bottom: 48px;
      border-top: none;
      ${row === 2 &&
        css`
          border-top: solid thin #d5d9e2;
          padding-top: 48px;
          padding-bottom: 0;
        `}
      ${col === 2 &&
        css`
          border-left: solid thin #d5d9e2;
          padding-left: 70px;
          padding-right: 0;
        `}
    }
  `}
`

const TitleWrapper = styled.div`
  ${({ theme: { colors } }) => css`
    background-color: ${colors.orange};
    padding: 17px 21px;

    ${up('menu')} {
      padding: 36px 70px;
    }
  `}
`

const BoxTitle = ({ color = 'orange', ...props }) => (
  <Title.Mini
    fontSize="22px"
    color={color}
    lineHeight="30px"
    letterSpacing="1.3px"
    fontWeight="700"
    mobileFontSize="15px"
    {...props}
  />
)

export const CustomizedGridItem = ({
  col,
  row,
  text,
  title,
  content,
  append,
  withoutBorder,
}) => (
  <GridItem col={col} row={row} withoutBorder={withoutBorder}>
    <Col>
      <BoxTitle>
        {title && '—'}&nbsp;&nbsp;{title}
      </BoxTitle>
      <Gap gap="26px" mobileGap="18px" />
      <Text>
        {text && <div dangerouslySetInnerHTML={{ __html: text }} />}
        {content}
      </Text>
      {append}
    </Col>
  </GridItem>
)

class CourseDetailBox extends React.Component {
  state = {}

  componentDidMount() {}

  render() {
    const { courseType } = this.props

    const chooseTermButtons = (
      <Col>
        <Gap gap="80px" mobileGap="46px" />

        <StyledRow responsive bp={1100}>
          <Button.SecondaryOrange
            iconLeft="arrowUp"
            link={{ anchor: Link.anchors.COURSES_BOX }}
          >
            Zvolit termín
          </Button.SecondaryOrange>

          {courseType.hasTasting && (
            <>
              <Gap gap="18px" />
              <Button.PrimaryGreen
                link={{ anchor: Link.anchors.COURSES_BOX }}
                iconLeft="signUp"
                onClick={() => {
                  this.props.setActiveCategory('TASTING')
                }}
              >
                ochutnávka
              </Button.PrimaryGreen>
            </>
          )}
        </StyledRow>
      </Col>
    )

    return (
      <Wrapper>
        <Col>
          <TitleWrapper>
            <BoxTitle color="white">{courseType.longName}</BoxTitle>
          </TitleWrapper>

          <Col>
            <Grid>
              <CustomizedGridItem
                col={1}
                row={1}
                text={courseType.aboutDesc}
                title="Náplň"
                withoutBorder
              />
              <CustomizedGridItem
                col={1}
                row={2}
                text={courseType.quantityDesc}
                title="Rozsah"
                append={(
                  <>
                    {(courseType.isAccredited ||
                      courseType.isAccreditedMPSV) && (
                      <Col>
                        <Gap gap="60px" mobileGap="18px" />

                        {courseType.isAccredited &&
                          courseType.accreditationDocument && (
                            <Text.Small>
                              Kurz je{' '}
                              <Link
                                asA
                                href={
                                  courseType.accreditationDocument.publicUrl
                                }
                                target="_blank"
                              >
                                akreditován MŠMT ČR
                              </Link>
                              .
                            </Text.Small>
                        )}

                        {courseType.isAccreditedMPSV &&
                          courseType.accreditationMPSVDocument && (
                            <>
                              <Gap
                                gap={courseType.isAccredited ? '8px' : '0'}
                                mobileGap="18px"
                              />
                              <Text.Small>
                                Kurz je{' '}
                                <Link
                                  asA
                                  href={
                                    courseType.accreditationMPSVDocument
                                      .publicUrl
                                  }
                                  target="_blank"
                                >
                                  akreditován MPSV ČR
                                </Link>
                                .
                              </Text.Small>
                            </>
                        )}
                      </Col>
                    )}
                  </>
                )}
              />

              {/* TOP RIGHT */}
              {courseType.lecturers && courseType.lecturers.length ? (
                <CustomizedGridItem
                  col={2}
                  row={1}
                  content={courseType.lecturers.map((lec, index) => (
                    <>
                      {index !== 0 && ', '}
                      <Link pageKey={Link.ABOUT} anchor={lec.anchor}>
                        {lec.fullName}
                      </Link>
                    </>
                  ))}
                  title="Lektoři"
                />
              ) : (
                <CustomizedGridItem
                  col={2}
                  row={1}
                  text={courseType.priceDesc}
                  title="Cena"
                />
              )}

              {/* BOTTOM RIGHT */}

              {courseType.lecturers && courseType.lecturers.length ? (
                <CustomizedGridItem
                  col={2}
                  row={2}
                  text={courseType.priceDesc}
                  title="Cena"
                  append={chooseTermButtons}
                />
              ) : (
                <CustomizedGridItem
                  col={2}
                  row={2}
                  text=""
                  title="Termín"
                  append={chooseTermButtons}
                />
              )}
            </Grid>
          </Col>
        </Col>
      </Wrapper>
    )
  }
}

CourseDetailBox.defaultProps = {}

export default CourseDetailBox
