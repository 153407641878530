import styled, { css } from 'styled-components'
import { up } from '../lib/styles'

export const Page = styled.div`
  ${({ theme: { sizes, colors }, pink, grey, bg, thinnerMobile }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    /* you can define bg by multiple options */
    background: ${colors[bg] || bg};
    background: ${pink && colors.bgPink};
    background: ${grey && colors.bgGrey};

    /* global page padding here! */
    padding: 0 20px;
    padding: ${thinnerMobile && '0 12px'};

    ${up('mobile')} {
      padding: 0 24px;
    }
  `}
`

export const PageContent = styled.div`
  ${({ height, position = 'static', theme: { sizes } }) => css`
    width: 100%;
    max-width: ${sizes.pageWidth}px;
    display: flex;
    flex-direction: column;
    align-content: stretch;
    height: ${height};
    position: ${position};
  `}
`
