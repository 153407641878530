// external imports
import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../../components/SEO'
import { Text, MethodPageLayout, Button, Gap } from '../../components'
import { links } from '../../links'

class Enrichment extends React.Component {
  componentDidMount() {}

  render() {
    const {
      location: { pathname },
      data: { image1, image2 },
    } = this.props
    const images = [image1, image2]
    return (
      <>
        <SEO
          title="Instrumentální obohacování"
          pathname={pathname}
        />
        <MethodPageLayout>
          <MethodPageLayout.Title>
            Instrumentální obohacování
          </MethodPageLayout.Title>
          <MethodPageLayout.TitleGap />
          <MethodPageLayout.Content images={images}>
            <Text.Wide>
              Metoda Instrumentálního obohacování (Feuerstein Instrumental
              Enrichment, FIE) představuje jeden z nejzajímavějších a
              nejpropracovanějších počinů na pomezí pedagogiky a psychologie
              druhé poloviny dvacátého století. V současné době ji nevyužívají
              pouze psychologové a učitelé, ale i rodiče, kteří v zájmu co
              nejlepšího výchovného působení na své děti nezapomínají na to, co
              je potřeba budovat nejvíce - schopnost myslet a učit se, mít
              motivaci, nepodléhat zklamání a celkově budovat v dorůstající
              generaci zdravé sebepojetí a celkové sebepřijetí. Metoda má své
              opodstatnění v sociální oblasti, ve zdravotnictví, může být
              přínosem pro kolektivy a týmy v jakémkoliv prostředí. Vedle umění
              vytvořit si strategii řešení úkolu upevňuje sebedůvěru, rozvíjí
              vyjadřování a respektování jiného názoru a umožňuje adaptaci v
              neustále se měnícím světě.
            </Text.Wide>
            <MethodPageLayout.ParagraphGap />
            <Text.Wide>
              Věta „Nechte mě chvilku…, já si to rozmyslím“ je mottem celého
              programu. Odkazuje na snahu překonávat impulsivitu při řešení
              problémových situací a zdůrazňuje význam individuální časové
              potřeby každého člověka při řešení úkolů.
            </Text.Wide>
            <MethodPageLayout.ParagraphGap />
            <Text.Wide>
              Proč obohacování? Feuerstein hovoří o obohacování kognitivních
              (poznávacích) struktur, protože předpokládá jejich
              modifikovatelnost. Všímá si těchto struktur v jejich aktuální,
              třeba i „neadekvátní“ podobě. Zkušenost zprostředkovaného učení
              (tzn. interakce, při níž rodiče, učitelé, vychovatelé „vkládají“
              sebe mezi podnět a dítě, aby zajistili co nejkvalitnější reakci)
              spolu s didaktickými principy obsaženými v Instrumentálním
              obohacování umožňuje nejprve se novým kognitivním operacím a
              strategiím naučit a později je samostatně používat všude, kde je
              toho třeba (metakognice).
            </Text.Wide>
            <MethodPageLayout.ParagraphGap />
            <Text.Wide>
              Proč instrumentální? Zhruba 350 stran úkolů je rozděleno do 14
              souborů = instrumentů (v řadě „Standard“, pro děti předškolního
              věku nebo jedince se zásadnějšími problémy existuje řada Basic), z
              nichž každý obsahuje úkoly typu „papír a tužka" sestavených tak,
              aby se postupně zvyšovala jejich náročnost i složitost v rámci
              podpory rozvoje deficitních kognitivních (poznávacích) funkcí.
              Plnění těchto úkolů otevírá možnost uvědomění si vlastních
              kognitivních procesů a dovedností. Studenti často nazývají tento
              proces jako „přemýšlení o přemýšlení“. Úkoly obsažené v
              instrumentech mají víceméně abstraktní charakter a jen velmi málo
              připomínají náplň „předmětů“ výuky, jak je známe z našich škol.
              Díky tomu nevyžadují předchozí znalosti k tomu, aby mohly být
              vyřešeny. Současná podoba Instrumentálního obohacování je
              výsledkem dlouholeté týmové práce Reuvena Feuersteina a jeho
              kolegů z výzkumného centra v Izraeli (HWCRI). Velkou úlohu při
              vzniku Instrumentálního obohacování sehrály zkušenosti, které
              Feuerstein získal v průběhu práce s kulturně handicapovanými dětmi
              v Maroku ve 40. a 50. letech 20. století, kdy spolupracoval
              především s André Reyem.
            </Text.Wide>
            <MethodPageLayout.ParagraphGap />
            <Text.Wide>
              Intervence prostřednictvím Instrumentálního obohacování má své
              nezastupitelné místo i nyní, jak potvrzuje stále širší úspěšná
              implementace metody v českém prostředí (školy, poradenská
              pracoviště, Vězeňská služba ČR, …) a existence výukových center
              metody v mnoha zemích světa.
            </Text.Wide>
            <Gap gap="40px" />
            <Button.SecondaryOrange
              link={{ pageKey: links.METHODS }}
              iconLeft="arrowLeft"
            >
              Zpátky na rozcestník
            </Button.SecondaryOrange>
          </MethodPageLayout.Content>
        </MethodPageLayout>
      </>
    )
  }
}

export default Enrichment

export const pageQuery = graphql`
  query {
    image1: file(relativePath: { eq: "images/methods/enrichment1.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 452, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image2: file(relativePath: { eq: "images/methods/enrichment2.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 452, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
