import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'
// import { darken, lighten } from 'polished'
import { withStyles } from '@material-ui/core/styles'
import { addResponsivity, up, down, between, addFont, colors } from '../../lib/styles'
import { Col, Row, Text, Title, Gap, Link, VH, Button } from '..'

const StyledCoreIcon = styled.span`
  ${({ theme: { colors } }) => css`
    ${addFont('signika')}
    font-weight: 700;
    font-size: 14px;
    color: #757575;
    width: 20px;
    height: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${colors.inputGrey};
    border-radius: 200px;
    cursor: pointer;

    &:hover {
      /* background: ${darken(0.1, colors.inputGrey)}; */
    }
  `}
`

const HintIcon = (...props) => <StyledCoreIcon {...props}>?</StyledCoreIcon>

export const StyledTooltip = withStyles(() => ({
  tooltip: {
    // backgroundColor: 'red',
    padding: 16,
    fontSize: 15,
    borderRadius: 0,
  },
  // arrow: {
  //   color: colors.black,
  // },
}))(Tooltip)

const HintTooltip = ({ hint, withoutGap }) => !hint ? null : (
  <>
    {!withoutGap && <Gap />}
    <StyledTooltip arrow title={hint} placement="top">
      <span>
        <HintIcon />
      </span>
    </StyledTooltip>
  </>
)

export default HintTooltip


