import React, { useEffect } from 'react'

function FormikObserver(props) {
  useEffect(() => {
    props.onChange(props.value)
  }, [Object.values(props.value).join(', ')])
  return null
}

FormikObserver.defaultProps = {
  onChange: () => null,
}


export default FormikObserver



/**
 * usage
   <FormikObserver value={values} onChange={value => console.log('asdfasdfasdfasdf',value)} />
 * 
 */