// external imports
import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../../components/SEO'
import { Text, MethodPageLayout, Button, Gap } from '../../components'
import { links } from '../../links'

class Approach extends React.Component {
  componentDidMount() {}

  render() {
    const {
      location: { pathname },
      data: { image1 },
    } = this.props
    const images = [image1]
    return (
      <>
        <SEO
          title="Feuersteinův přístup"
          pathname={pathname}
        />
        <MethodPageLayout>
          <MethodPageLayout.Title>Feuersteinův přístup</MethodPageLayout.Title>
          <MethodPageLayout.TitleGap />
          <MethodPageLayout.Content images={images}>
            <Text.Wide>
              Přestože klade Feuerstein největší důraz na kognitivní hledisko
              modifikovatelnosti, neopomíjí ani faktory emoční a sociální.
              Kognice využívá zkušeností, emočních prožitků a souvisí s duševní
              mobilitou člověka. Přirozenou vlastností našeho mozku je schopnost
              změny poznávacích struktur (kognice). Dokazuje to jednoduchá
              Feuersteinova úvaha: Pokud žijeme v určitých podmínkách a ty se
              vlivem okolností změní, v reakci na změnu se rozvíjí nové
              struktury, díky nimž jsme i v jiných podmínkách schopni nadále
              žít. Tato skutečnost stojí za Feuersteinovým striktním odmítnutím
              uvažovat např. o dyslexii a learning disability (poruchách učení,
              nejběžnější výraz pro dyslexii v americké literatuře) jako o
              něčem, co když jednou je, nedá se s tím příliš dělat a nezbývá než
              tento deficit přijmout. Podle něj se jedná pouze o termíny
              charakterizující určitý (dočasný) stav. Principy Feuersteinovy
              metody cílí na rozvoj umění učit se, jejich prostřednictvím se
              odhaluje učební potenciál jedince, který se v potřebných oblastech
              dále posiluje. Základem metody je pohled na lidský organismus jako
              na otevřený systém schopný adaptace a změny. V práci s metodou
              dochází ke stimulaci kognitivního rozvoje, který umožňuje školní,
              sociální a osobnostní růst, což z ní činí nejpropracovanější
              systém rozvoje dovedností myslet a učit se.
            </Text.Wide>
            <Gap gap="40px" />
            <Button.SecondaryOrange
              link={{ pageKey: links.METHODS }}
              iconLeft="arrowLeft"
            >
              Zpátky na rozcestník
            </Button.SecondaryOrange>
          </MethodPageLayout.Content>
        </MethodPageLayout>
      </>
    )
  }
}

export default Approach

export const pageQuery = graphql`
  query {
    image1: file(relativePath: { eq: "images/methods/approach1.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 452, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
