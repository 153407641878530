import React from 'react'

import styled, { css } from 'styled-components'
import { Col, Row, Title, Page, PageContent, Gap } from '.'
import { up } from '../lib/styles'

const Bar = styled.div`
  ${({ theme: { colors } }) => css`
    width: 100%;
    min-height: 190px;
    background: ${colors.greenGradient};
    position: relative;

    align-items: center;
    justify-content: center;
    display: flex;

    ${up('mobile')} {
      min-height: 230px;
    }
  `}
`

const HeaderSubtitle = props => (
  <Title
    fontSize="15px"
    lineHeight="22px"
    fontWeight="400"
    mobileFontSize="12px"
    mobileLineHeight="18px"
    color="#f0f2f6;"
    letterSpacing="1.8px"
    as="h2"
    upperCase
    textAlign="left"
    mobileTextAlign="center"
    {...props}
  />
)

const ImageWrapper = styled.div`
  ${({
    padding = '0 20px 0 20px',
    mobileMaxWidth = '100%',
    maxWidth = '100%',
    width = 'auto',
  }) => css`
    width: 100%;
    img {
      max-height: 100%;
      max-width: 100%;
    }
    max-height: 100%;
    max-width: ${mobileMaxWidth};
    flex-shrink: 2;

    ${up('mobile')} {
      width: ${width};
      max-height: 100%;
      max-width: ${maxWidth};
      padding: ${padding};
    }
  `}
`

const HeaderBar = ({ children, maxWidth = '100%', ...props }) => (
  <Bar {...props}>
    <Page>
      <PageContent height="100%">
        <Row
          alignItems="center"
          justifyContent="space-between"
          height="100%"
          width="100%"
          maxWidth={maxWidth}
          responsive
          margin="auto"
        >
          {children}
        </Row>
        <Gap hideAfter="mobile" gap="40px" />
      </PageContent>
    </Page>
  </Bar>
)

HeaderBar.Title = props => (
  <>
    <Title.Page textAlign="left" mobileTextAlign="center" {...props} />
    <Gap gap="24px" mobileGap="6px" />
  </>
)
HeaderBar.SubTitle = HeaderSubtitle
HeaderBar.ImageWrapper = ImageWrapper

HeaderBar.Content = ({ children, ...props }) => (
  <Col
    alignItems="flex-start"
    mobileAlignItems="center"
    mobileTextAlign="center"
    shrink={0}
    {...props}
  >
    <Gap hideAfter="mobile" mobileGap="34px" />
    {children}
  </Col>
)

export default HeaderBar
