/* eslint-disable react/prefer-stateless-function */
/**
 * This is wrapper for page, extracting ks query data and applying langs
 *
 */

import React from 'react'
import { extractDataAndApplyLangs } from './helpers'

export default WrappedComponent =>
  class extends React.Component {
    render() {
      const {
        intl: { language },
      } = this.props

      const newProps = {
        ...this.props,
        data: extractDataAndApplyLangs(this.props.data, language)
      }

      return <WrappedComponent {...newProps} />
    }
  }
