import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import Slider from '@material-ui/core/Slider'
import { withStyles } from '@material-ui/core/styles'
import HintTooltip from './HintTooltip'
import Error from './Error'
import Label from './Label'
import { addResponsivity, up, down, between, addFont, colors } from '../../lib/styles'
import { Col, Row, Text, Title, Gap, Link, VH, Button } from '..'

const StyledSlider = withStyles({
  root: {
    color: colors.orange,
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
    backgroundColor: colors.checkRadioGrey,
  },
})(Slider)

const SliderValue = styled.label`
  ${({ theme: { colors } }) => css`
    ${addFont('signika')}
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  `}
`

const SliderField = ({
  field,
  form: { errors, setFieldTouched, setFieldValue, touched },
  meta,
  label,
  hint,
  max,
  min,
  ...props
}) => {
  const handleSliderChange = (event, newValue) => {
    setFieldTouched(field.name)
    setFieldValue(field.name, newValue)
  }

  return (
    <Col alignItems="center">
      <Col alignSelf="flex-start">
        <Row alignItems="center">
          <Label htmlFor={props.id || field.name}>{label}</Label>
          <HintTooltip hint={hint} />
        </Row>
      </Col>
      <Gap />
      <SliderValue>
        {field.value}
        {' '}
        PRMS
      </SliderValue>
      <StyledSlider
        max={max}
        min={min}
        value={typeof field.value === 'number' ? field.value : 0}
        onChange={handleSliderChange}
      />

      {/* no errors, possibly do it in the future */}
    </Col>
  )
}

export default SliderField
