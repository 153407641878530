import React from 'react'
import { Link, Text, Gap, PostThumbnail, Col } from '.'
import T from '../lib/intl/T'
import { formatDate } from '../lib/helpers'

const PostList = ({ posts }) => (
  <PostThumbnail.Grid>
    {posts.map(post => (
      <PostThumbnail key={post.id}>
        <Link.Simple pageKey={Link.POST} slugs={post.slugs}>
          <PostThumbnail.Image
            fluid={post.image.file && post.image.file.img.fluid}
          />
        </Link.Simple>
        <PostThumbnail.Content>
          <Col>
            <Link.Simple pageKey={Link.POST} slugs={post.slugs}>
              <PostThumbnail.Title>{post.title}</PostThumbnail.Title>
            </Link.Simple>
            <Gap gap="32px" />
            <Text.Small letterSpacing="1.45px">
              <T>PUBLIKOVÁNO</T> {formatDate(post.publishedAt)}
            </Text.Small>
          </Col>
          <Gap gap="24px" />
          <Text>
            <div
              dangerouslySetInnerHTML={{
                __html: post.perex,
              }}
            />
          </Text>
          <Gap gap="24px" />
          <Link.OrangeLink pageKey={Link.POST} slugs={post.slugs}>
            » <T>ČÍST DÁL</T>
          </Link.OrangeLink>
        </PostThumbnail.Content>
      </PostThumbnail>
    ))}
  </PostThumbnail.Grid>
)

export default PostList
