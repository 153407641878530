// external imports
import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../../components/SEO'
import { links } from '../../links'
import { Text, MethodPageLayout, Button, Gap } from '../../components'
import T from '../../lib/intl/T'

class Lpad extends React.Component {
  componentDidMount() {}

  render() {
    const {
      location: { pathname },
      data: { image1 },
    } = this.props
    const images = [image1]
    return (
      <>
        <SEO title="LPAD" pathname={pathname} />
        <MethodPageLayout>
          <MethodPageLayout.Title>LPAD</MethodPageLayout.Title>
          <MethodPageLayout.TitleGap />
          <MethodPageLayout.Content images={images}>
            <Text.Wide>
              <T>lpad-text</T>
              <br />
              <br />
              Kurz můžete absolvovat přes spřátelenou organizaci{' '}
              <a
                href="https://www.dyscentrum.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                DYS-centrum® Praha
              </a>
              .
            </Text.Wide>
            <Gap gap="40px" />
            <Button.SecondaryOrange
              link={{ pageKey: links.METHODS }}
              iconLeft="arrowLeft"
            >
              Zpátky na rozcestník
            </Button.SecondaryOrange>
          </MethodPageLayout.Content>
        </MethodPageLayout>
      </>
    )
  }
}

export default Lpad

export const pageQuery = graphql`
  query {
    image1: file(relativePath: { eq: "images/methods/lpad1.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 452, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
