// external imports
import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'
// helpers
import { up, addResponsivity } from '../lib/styles'
import { extractNodes, extractNodesFromData } from '../lib/helpers'
import injectIntl from '../lib/intl/injectIntl'

// components
import SEO from '../components/SEO'
import {
  Page,
  PageContent,
  Title,
  Gap,
  Row,
  Col,
  Link,
  Text,
  RH,
  Button,
  HeaderBar,
  PeopleList,
  Line,
  SubHeaderBar,
} from '../components'
import T from '../lib/intl/T'

import headerBarImageSrc from '../data/images/about-top.png'

import coop1Src from '../data/images/about-page/dys-centrum.png'
import coop2Src from '../data/images/about-page/charlie.png'
import coop3Src from '../data/images/about-page/centrum-rozumu.png'
import coop4Src from '../data/images/about-page/MYSLENIE-PRE-ZIVOT.png'

import partner1Src from '../data/images/about-page/univerzita-palackeho.png'
import partner2Src from '../data/images/about-page/poradna-pro-integraci.png'
import partner3Src from '../data/images/about-page/dps.png'
import partner4Src from '../data/images/about-page/bader.png'
import partner5Src from '../data/images/about-page/edu-spec.png'

import fileIcon from '../data/images/icons/file-icon.svg'

import withExtraction from '../lib/withExtraction'
import { isCs, isSk } from '../lib/appVersions'

const coopImages = [
  { src: coop1Src, alt: 'DYS centrum', url: 'http://www.dyscentrum.org' },
  { src: coop2Src, alt: 'Charlie', url: 'http://www.charliekarlin.cz' },
  { src: coop3Src, alt: 'Centrum Rozumu', url: 'https://www.centrumrozum.cz' },
  {
    src: coop4Src,
    alt: 'OZ Myslenie pre život',
    url: 'https://myslenieprezivot.sk/',
  },
]

const partnersImages = [
  {
    src: partner1Src,
    alt: 'Univerzita Palackého v Olomouci',
    url: 'https://www.upol.cz/',
  },
  { src: partner2Src, alt: 'Poradna pro integraci', url: 'http://p-p-i.cz/' },
  { src: partner3Src, alt: 'dps', url: 'https://ondrejov.cz/' },
  { src: partner4Src, alt: 'BADER', url: 'https://www.bader.org/' },
  { src: partner5Src, alt: 'EDU-spec', url: ' https://edu-spec.sk/' },
]

const ImageWrapper = styled.div`
  img {
    width: 140px;
    max-width: 100%;
  }
`

const ImagesRow = ({ data, bp = 500 }) => (
  <Row
    width="100%"
    justifyContent="center"
    alignItems="center"
    position="relative"
    responsive
    bp={bp}
  >
    {data.map(({ src, alt, url }, key) => (
      <Fragment key={key}>
        <ImageWrapper>
          <Gap gap="15px" mobileGap="0" />
          <Link asA target="_blank" href={url}>
            <img src={src} alt={alt} />
          </Link>
          <Gap gap="15px" mobileGap="0" />
        </ImageWrapper>
        {key + 1 !== data.length && (
          <>
            <Gap gap="40px" mobileGap="14px" />
            <Line isVertical showAfter={bp} />
            <Gap gap="40px" mobileGap="14px" />
          </>
        )}
      </Fragment>
    ))}
  </Row>
)

const FilesRow = ({ data }) => (
  <Row wrap="wrap" justifyContent="center">
    {data.map(({ title, file: { publicUrl } }, key) => (
      <Fragment key={key}>
        <a href={publicUrl} target="_blank">
          <Row alignItems="center" padding="10px">
            <img src={fileIcon} alt="file icon" style={{ height: '20px' }} />
            <Gap gap="10px" />
            <Text>{title}</Text>
            <Gap gap="20px" />
          </Row>
        </a>
      </Fragment>
    ))}
  </Row>
)

class About extends React.Component {
  componentDidMount() {}

  render() {
    const {
      location: { pathname },
      intl: { language, t },
      data: { allPeople, allDocuments, topImage },
    } = this.props

    const skTeam = allPeople.filter(({ sortOrder }) => sortOrder < 100)
    const csTeam = allPeople.filter(({ sortOrder }) => sortOrder >= 100)

    return (
      <>
        <SEO title={t('O nás')} pathname={pathname} />

        <HeaderBar>
          <HeaderBar.Content shrink={1}>
            <HeaderBar.Title>
              <T>O nás</T>
            </HeaderBar.Title>
            <HeaderBar.SubTitle>
              <T>kdo jsme, co všechno děláme a s kým spolupracujeme</T>
            </HeaderBar.SubTitle>
          </HeaderBar.Content>
          {/* <HeaderBar.ImageWrapper
            padding="0"
            mobileMaxWidth="500px"
            width="50%"
          >
            <Img fluid={topImage.image.fluid} style={{ width: '100%' }} />
          </HeaderBar.ImageWrapper> */}
        </HeaderBar>

        <SubHeaderBar contentMaxWidth="1168px">
          <SubHeaderBar.Text>
            <T raw>
              {
                '<strong>Autorizované tréninkové centrum</strong> metod profesora Reuvena Feursteina vzniklo v listopadu 2015 jako projev snahy skupiny odborníků nabízet a šířit metody profesora Reuvena Feuersteina v českém a slovenském prostředí. Do týmu našeho ATC patří řada ředitelů škol, učitelů, speciálních pedagogů, psychologů, lidí na nejrůznějších jiných pozicích.'
              }
            </T>
          </SubHeaderBar.Text>
          <Gap gap="58px" mobileGap="48px" />
          <SubHeaderBar.Text>
            <T raw>
              {
                'Součástí <strong>našeho ATC týmu</strong> jsou všichni, kdo se zasloužili o propagaci Feuersteinových metod a stále v tom pokračují, spolupracují na organizaci nejrůznějších akcí nebo jsou jednoduše podpůrní celému ději v Autorizovaném tréninkovém centru metod prof. Reuvena Feuersteina. <strong>Děkujeme!</strong>'
              }
            </T>
          </SubHeaderBar.Text>
        </SubHeaderBar>

        <Page>
          <PageContent>
            <Col alignItems="center">
              <Gap gap="90px" mobileGap="45px" />

              {isCs && (
                <>
                  <Title.Section>
                    <T>Náš tým</T>
                  </Title.Section>
                  <Gap gap="34px" mobileGap="45px" />
                  <PeopleList allPeople={allPeople} />
                  <Gap gap="142px" />
                </>
              )}

              {isSk && (
                <>
                  <Title.Section>
                    <T>Tím OZ Myslenie</T>
                  </Title.Section>
                  <Gap gap="34px" mobileGap="45px" />
                  <PeopleList allPeople={skTeam} />
                  <Gap gap="142px" />

                  <Title.Section>
                    <T>Tím ATC</T>
                  </Title.Section>
                  <Gap gap="34px" mobileGap="45px" />
                  <PeopleList allPeople={csTeam} />
                  <Gap gap="142px" />
                </>
              )}

              <Title.Section>
                <T>Spolupracujeme</T>
              </Title.Section>
              <Gap gap="40px" />
              <ImagesRow data={coopImages} />
              <Gap gap="170px" mobileGap="80px" />
              <Title.Section>
                <T>Partneři</T>
              </Title.Section>
              <Gap gap="40px" />
              <ImagesRow data={partnersImages} />
              <Gap gap="170px" mobileGap="80px" />
              <Title.Section>
                <T>Výroční zprávy</T>
              </Title.Section>
              <Gap gap="40px" mobileGap="20px" />
              <FilesRow
                data={allDocuments.filter(
                  ({ section }) => section === 'REPORT'
                )}
              />
              <Gap gap="160px" mobileGap="80px" />
              <Title.Section>
                <T>Dokumenty ke stažení</T>
              </Title.Section>
              <Gap gap="40px" mobileGap="20px" />
              <FilesRow
                data={allDocuments.filter(
                  ({ section }) => section === 'DOCUMENT'
                )}
              />
              <Gap gap="160px" mobileGap="80px" />
            </Col>
          </PageContent>
        </Page>
      </>
    )
  }
}

export default injectIntl(withExtraction(About))

export const pageQuery = graphql`
  query {
    topImage: file(relativePath: { eq: "images/about-top.png" }) {
      image: childImageSharp {
        fluid(maxWidth: 660, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ks {
      allPeople(sortBy: sortOrder_ASC) {
        ...PersonFragment
      }
      allDocuments(sortBy: sortOrder_ASC) {
        ...DocumentFragment
      }
    }
  }
`
