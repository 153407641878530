import React, { Component, useState } from 'react'
import styled, { css } from 'styled-components'
import HintTooltip from './HintTooltip'
import Error from './Error'
import Label from './Label'
import {
  addResponsivity,
  up,
  down,
  between,
  addFont,
  colors,
} from '../../lib/styles'
import { Col, Row, Text, Title, Gap, Link, VH, Button } from '..'

import checkboxTickPath from '../../data/images/icons/checkbox.svg'

const StyledCheckbox = styled.div`
  ${({ theme: { colors }, error, checked }) => css`
    background: white;
    border-radius: 0;
    border: 1px solid #818181;
    width: 16px;
    height: 16px;
    position: relative;
    flex-basis: 16px;
    min-width: 16px;

    &:after {
      display: block;
      position: absolute;
      width: 11px;
      height: 8px;
      content: '';
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) translateX(0px);
      background: url(${checkboxTickPath});
      opacity: 0;
      transition: 0.15s;
      z-index: 1;
    }

    ${checked &&
      css`
        /* background: white;
     */
        &:after {
          opacity: 1;
        }
      `}

    ${error &&
      css`
        border: 2px solid #e24849;
      `}
  `}
`

const CheckboxField = ({
  field,
  form: { errors, setFieldTouched, setFieldValue, touched },
  meta,
  hint,
  label,
  sideEffect,
  ...props
}) => {
  const handleChange = e => {
    const value = !field.value
    if (sideEffect) {
      sideEffect({
        value,
        setFieldTouched,
        setFieldValue,
      })
    }
    setFieldTouched(field.name)
    setFieldValue(field.name, value)
    // setFieldValue() // sadly this is necessary to trigger persistence
  }

  const name = props.id || field.name

  return (
    <Col>
      <Row alignItems="flex-start">
        <VH>
          <input
            id={name}
            {...field}
            {...props}
            type="checkbox"
            checked={field.value}
            onChange={handleChange}
          />
        </VH>
        <StyledCheckbox
          checked={field.value}
          error={touched[field.name] && errors[name]}
          onClick={handleChange}
        />
        <Gap />
        <Col>
          <Label
            smaller
            htmlFor={name}
            error={touched[field.name] && errors[name]}
          >
            {label}
          </Label>
          <Error name={name} relative />
          <Gap gap="16px" />
        </Col>
      </Row>
    </Col>
  )
}

export const PureCheckbox = ({
  label,
  onChange,
  value: controlledValue,
  ...props
}) => {
  const name = props.id || props.name

  const [value, setValue] = useState(false)
  const handleChange = () => setValue(!value)
  const controlledHandleChange = onChange
    ? () => onChange(!controlledValue)
    : null

  return (
    <Col>
      <Row alignItems="center">
        <VH>
          <input
            id={name}
            {...props}
            type="checkbox"
            checked={controlledValue || value}
            onChange={controlledHandleChange || handleChange}
          />
        </VH>
        <StyledCheckbox
          checked={controlledValue || value}
          onClick={controlledHandleChange || handleChange}
        />
        <Gap />
        <Label smaller htmlFor={name}>
          {label}
        </Label>
      </Row>
    </Col>
  )
}

export default CheckboxField
