import React from 'react'
import styled, { css } from 'styled-components'
// import { darken, lighten, saturate } from 'polished'
import { Link } from '.'
import { down, up } from '../lib/styles'
import Gap from './Gap'

const iconPaths = {
  trolley: require('../data/images/icons/trolley.svg'),
  signUp: require('../data/images/icons/sign-up.svg'),
  doubleArrowDown: require('../data/images/icons/double-arrow-down.svg'),
  arrowDown: require('../data/images/icons/arrow-down.svg'),
  arrowUp: require('../data/images/icons/arrow-up.svg'),
  arrowLeft: require('../data/images/icons/arrow-left.svg'),
  email: require('../data/images/icons/email.svg'),
  search: require('../data/images/icons/search.svg'),
  web: require('../data/images/icons/web.svg'),
}

const IconWrapper = styled.div`
  ${({ bp = 'mobile' }) => css`
    height: 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 16px;
    ${up(bp)} {
      width: 20px;
    }
  `}
`

const IconImg = styled.div`
  ${({ src, color, bp = 'mobile' }) => css`
    transition: 0.3s;
    background-color: ${color};
    mask: url(${src}) no-repeat center;
    mask-size: contain;

    width: 16px;
    height: 16px;
    ${up(bp)} {
      width: 20px;
      height: 20px;
    }
  `}
`

// only base for other buttons, do not use this
const StyledButtonBase = styled.div`
  ${({
    theme: { colors },
    padding = '14px 0',
    width,
    maxWidth,
    responsiveFullWidth,
    bp = 'mobile',
    disabled,
    alignSelf,
    bgColor,
    bgColorHover,
    color,
    colorHover,
    outlineColor,
    outlineColorHover,
    fontWeight = 600,
    isFull,
  }) => css`
    min-width: 100px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: ${fontWeight};
    letter-spacing: 2px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.3s;
    padding: ${padding};
    width: ${width};
    max-width: ${maxWidth};
    align-self: ${alignSelf};

    font-size: 12px;
    line-height: 16px;
    ${up(bp)} {
      font-size: 15px;
      line-height: 20px;
    }

    background-color: ${colors[bgColor] || bgColor};
    background-color: ${disabled && isFull && colors.disabledBtn};

    color: ${colors[color] || color};
    color: ${disabled && !isFull && colors.disabledBtn};

    ${IconImg} {
      background-color: ${colors[color] || color};
      background-color: ${disabled && !isFull && colors.disabledBtn};
    }

    &:hover {
      background-color: ${colors[bgColorHover] || bgColorHover};
      color: ${colors[colorHover] || colorHover};

      ${IconImg} {
        background-color: ${colors[colorHover] || colorHover};
      }
    }

    ${outlineColor &&
      css`
        border: ${colors[outlineColor] || outlineColor} solid 2px;
        border-color: ${disabled && colors.disabledBtn};
      `}

    ${outlineColorHover &&
      css`
        &:hover {
          border: ${colors[outlineColorHover] || outlineColorHover} solid 2px;
        }
      `}

    ${disabled &&
      css`
        pointer-events: none;
      `}

    ${responsiveFullWidth &&
      css`
        ${down(bp)} {
          width: 100%;
        }
      `}
  `}
`

const Icon = ({ ...props }) => (
  <IconWrapper>
    <IconImg {...props} />
  </IconWrapper>
)

const LinkWrapper = styled(Link)`
  ${({ theme, responsiveFullWidth, bp = 'mobile' }) => css`
    ${responsiveFullWidth &&
      css`
        ${down(bp)} {
          width: 100%;
        }
      `}
  `}
`

const ButtonBase = ({
  children,
  iconLeft,
  paddingLeft,
  paddingRight,
  mobilePaddingLeft,
  mobilePaddingRight,
  iconRight,
  link,
  iconGap = '16px',
  disabled,
  ...props
}) => {
  let leftPadding = '36px'
  let rightPadding = '36px'

  if (iconLeft) {
    leftPadding = '20px'
  }
  if (iconRight) {
    rightPadding = '20px'
  }

  leftPadding = paddingLeft || leftPadding
  rightPadding = paddingRight || rightPadding

  const leftPaddingMobile = mobilePaddingLeft || leftPadding
  const rightPaddingMobile = mobilePaddingRight || rightPadding

  const WrapperComponent = link && !disabled ? LinkWrapper : React.Fragment

  const linkArgs = {
    noStyle: true,
    responsiveFullWidth: props.responsiveFullWidth,
    bp: props.bp,
    ...link,
  }

  return (
    <WrapperComponent {...(link && linkArgs)}>
      <StyledButtonBase disabled={disabled} {...props}>
        <Gap gap={leftPadding} mobileGap={leftPaddingMobile} onlyWidth />
        {iconLeft && (
          <>
            <Icon src={iconPaths[iconLeft]} />
            <Gap gap={iconGap} onlyWidth />
          </>
        )}
        {children}
        {iconRight && (
          <>
            <Gap gap={iconGap} onlyWidth />
            <Icon src={iconPaths[iconRight]} />
          </>
        )}
        <Gap gap={rightPadding} mobileGap={rightPaddingMobile} onlyWidth />
      </StyledButtonBase>
    </WrapperComponent>
  )
}

const PrimaryGreen = ({ ...props }) => (
  <ButtonBase
    color="white"
    colorHover="white"
    bgColor="lightGreen"
    bgColorHover="green"
    outlineColor="lightGreen"
    outlineColorHover="green"
    isFull
    {...props}
  />
)

const PrimaryOrange = ({ ...props }) => (
  <ButtonBase
    color="white"
    colorHover="white"
    bgColor="orange"
    bgColorHover="darkOrange"
    outlineColor="orange"
    outlineColorHover="darkOrange"
    isFull
    {...props}
  />
)

const SecondaryOrange = ({ ...props }) => (
  <ButtonBase
    color="orange"
    colorHover="white"
    bgColor="transparent"
    bgColorHover="orange"
    outlineColor="orange"
    {...props}
  />
)

const SecondaryGreen = ({ ...props }) => (
  <ButtonBase
    color="lightGreen"
    colorHover="white"
    bgColor="transparent"
    bgColorHover="lightGreen"
    outlineColor="lightGreen"
    {...props}
  />
)

// ButtonLink = ({
//   dashed,
//   responsiveFullWidth,
//   buttonProps,
//   children,
//   bp,
//   ...rest
// }) => {
//   const Component = dashed ? Button.Dashed : Button

//   return (
//     <ButtonLink
//       noStyle
//       responsiveFullWidth={responsiveFullWidth}
//       bp={bp}
//       {...rest}
//     >
//       <Component
//         {...rest}
//         responsiveFullWidth={responsiveFullWidth}
//         bp={bp}
//         children={children}
//       />
//     </ButtonLink>
//   )
// }
export default {
  PrimaryGreen,
  PrimaryOrange,
  SecondaryOrange,
  SecondaryGreen,
}
