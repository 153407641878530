import React from 'react'
import styled, { css } from 'styled-components'
import { Row, Page, PageContent, Gap, Text } from '.'

const Content = props => (
  <Row
    maxWidth="1200px"
    mobileMaxWidth="500px"
    width="100%"
    justifyContent="center"
    margin="0"
    mobileMargin="auto"
    responsive
    {...props}
  />
)

const SubHeaderBar = ({
  children,
  contentMaxWidth,
  style = {},
  white,
  ...props
}) => (
  <Page grey={!white}>
    <PageContent style={{ minHeight: '292px', ...style }}>
      <Gap gap="80px" mobileGap="48px" />
      <Content maxWidth={contentMaxWidth}>{children}</Content>
      <Gap gap="80px" mobileGap="48px" />
    </PageContent>
  </Page>
)

SubHeaderBar.Content = Content
SubHeaderBar.Text = props =>
  <Text Text color="darkGreen" letterSpacing="0.75px" {...props} />

export default SubHeaderBar
