// external imports
import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'

// helpers
import moment from 'moment'
import { up, addResponsivity } from '../lib/styles'
import { extractNodes, extractNodesFromData } from '../lib/helpers'
import injectIntl from '../lib/intl/injectIntl'
import withExtraction from '../lib/withExtraction'
import { categoriesKeysObject } from '../courses'

// components
import SEO from '../components/SEO'
import {
  Title,
  Gap,
  Row,
  Col,
  Link,
  Text,
  RH,
  Button,
  HeaderBar,
  Card,
  SubHeaderBar,
} from '../components'
import { Page, PageContent } from '../components/Page'
import ClosestCoursesBox from '../components/ClosestCoursesBox'
import headerBarImageSrc from '../data/images/journey-top.png'
import { t } from '../lib/intl'
import T from '../lib/intl/T'
import { RichTextContent } from '../components/Card'

class Journey extends React.Component {
  componentDidMount() {}

  render() {
    const {
      location: { pathname },
      intl: { language },
    } = this.props

    const { allCourseTerms } = this.props.data

    const itemsToList = allCourseTerms
      .filter(term => term.isPublished)
      .filter(term => moment(term.from, 'YYYY-MM-DD').isAfter(moment()))
      .filter(
        term =>
          term.courseType.category === categoriesKeysObject.FIE_STANDARD &&
          moment(term.from, 'YYYY-MM-DD').isAfter(moment()) &&
          !term.courseType.isFIE1
      )
      .slice(0, 10)

    return (
      <>
        <SEO title={t('Cesta lektora')} pathname={pathname} />
        <HeaderBar>
          <HeaderBar.Content>
            <HeaderBar.Title>
              <T>Cesta lektora</T>
            </HeaderBar.Title>
            <HeaderBar.SubTitle>
              <T>staráme se o lektora po celou dobu jeho cesty</T>
            </HeaderBar.SubTitle>
          </HeaderBar.Content>
        </HeaderBar>

        <SubHeaderBar contentMaxWidth="1220px">
          <SubHeaderBar.Text>
            <T raw>cesta lektora - intro 1</T>
          </SubHeaderBar.Text>
          <Gap gap="140px" mobileGap="48px" />
          <SubHeaderBar.Text>
            <T raw>cesta lektora - intro 2</T>
          </SubHeaderBar.Text>
        </SubHeaderBar>

        <Page>
          <Gap gap="90px" mobileGap="60px" />
          <PageContent>
            <Card.Grid>
              <Card>
                <Card.Header>
                  <T raw>{`POSKYTNUTÍ <br /> INFORMACÍ`}</T>
                </Card.Header>
                <Card.Content>
                  <Card.PencilIcon />
                  <Card.Text>
                    <RichTextContent>
                      {t('journey - box 1 - content')}
                    </RichTextContent>
                  </Card.Text>
                  <Card.Button
                    link={{
                      asA: true,
                      href: 'mailto:info@a-t-c.cz',
                    }}
                  >
                    <T>napište nám</T>
                  </Card.Button>
                </Card.Content>
              </Card>
              <Card>
                <Card.Header>
                  <T>zaškolení na úvodním kurzu</T>
                </Card.Header>
                <Card.Content>
                  <Card.HatIcon />
                  <Card.Text>
                    <RichTextContent>
                      {t('journey - box 2 - content')}
                    </RichTextContent>
                  </Card.Text>
                  <Card.Button
                    link={{
                      pageKey: Link.COURSES,
                      anchor: Link.anchors.COURSES_BOX,
                      params: { active: categoriesKeysObject.FIE_STANDARD },
                    }}
                  >
                    <T>detail kurzu</T>
                  </Card.Button>
                </Card.Content>
              </Card>
              <Card>
                <Card.Header>
                  <T>podpora v průběhu praxe</T>
                </Card.Header>
                <Card.Content>
                  <Card.ConnectedPersonsIcon />
                  <Card.Text>
                    <RichTextContent>
                      {t('journey - box 3 - content')}
                    </RichTextContent>
                  </Card.Text>
                  <Card.Button
                    link={{
                      pageKey: Link.COURSES,
                      anchor: Link.anchors.COURSES_BOX,
                      params: { active: categoriesKeysObject.TASTING },
                    }}
                  >
                    <T>více inspirace</T>
                  </Card.Button>
                </Card.Content>
              </Card>
              <Card>
                <Card.Header>
                  <T>návazné vzdělávání</T>
                </Card.Header>
                <Card.Content>
                  <Card.BookIcon />
                  <Card.Text>
                    <RichTextContent>
                      {t('journey - box 4 - content')}
                    </RichTextContent>
                  </Card.Text>
                  <Card.Button
                    link={{
                      pageKey: Link.COURSES,
                    }}
                  >
                    <T>naše kurzy</T>
                  </Card.Button>
                </Card.Content>
              </Card>
            </Card.Grid>
          </PageContent>
        </Page>
        <Gap gap="128px" mobileGap="60px" />

        <Page bg="bgGrey">
          <PageContent>
            <Gap gap="60px" mobileGap="60px" />
            <Col width="100%" alignItems="stretch">
              <ClosestCoursesBox
                items={itemsToList}
                title={t('Nejbližší nabízené návazné kurzy')}
                link={{ pageKey: Link.COURSES }}
              />
            </Col>
            <Gap gap="120px" mobileGap="70px" />
          </PageContent>
        </Page>
      </>
    )
  }
}

export default injectIntl(withExtraction(Journey))

export const pageQuery = graphql`
  query {
    ks {
      allCollaborators(where: { isPublished: true }) {
        ...CollaboratorFragment
      }
      allCourseTerms(sortBy: from_ASC) {
        ...CourseTermFragment
        ...CourseTermNestedFragment
      }
    }
  }
`
