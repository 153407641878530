import React from 'react'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import { up, addResponsivity } from '../lib/styles'
import { Title, Row, Gap, Col, Text } from '.'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 60px;
  width: 100%;
  margin: '0 auto';

  ${up('mobile')} {
    grid-template-columns: repeat(2, 1fr);
    max-width: 890px;
  }
  ${up('desktop')} {
    max-width: 100%;
    grid-template-columns: repeat(3, 1fr);
  }
`

const Name = props => (
  <Title
    fontSize="33px"
    lineHeight="40px"
    mobileFontSize="24px"
    mobileLineHeight="30px"
    fontWeight="200"
    letterSpacing="0.74px"
    color="#24423b"
    as="h4"
    bp={500}
    {...props}
  />
)

const Degree = props => (
  <Name
    fontSize="15px"
    lineHeight="15px"
    mobileFontSize="12px"
    mobileLineHeight="12px"
    fontWeight="400"
    {...props}
  />
)

const Image = styled(Img)`
  max-width: 100%;
  width: 100%;
`

const Person = styled.div`
  ${({ theme }) => css`
    max-width: 400px;
    width: 100%;
    /* min-height: 700px; */
    background: white;
    list-style: none;
    box-shadow: 2px 3px 81px rgba(0, 0, 0, 0.16);
    position: relative;
    margin: 0 auto;
    ${up('desktop')} {
      &:nth-child(3n + 2) {
        top: 40px;
      }
    }
  `}
`

const Position = styled.div`
  ${({ theme: { colors } }) => css`
    position: relative;
    top: -12px;
    padding: 0 25px;
    height: 24px;
    background-color: ${colors.orange};
    color: white;
    font-size: 11px;
    font-weight: 400;
    line-height: 23px;
    text-transform: uppercase;
    letter-spacing: 2.27px;
    text-align: center;
    margin: auto;
  `}
`

const EmailLink = styled.a`
  ${({ theme: { colors } }) => css`
    color: ${colors.orange};
    font-weight: 700;
    letter-spacing: 2.24px;
    line-height: 33.55px;
    text-decoration: underline;
    text-transform: uppercase;
    cursor: pointer;
    padding: 5px;
  `}
`

const PeopleList = ({ allPeople }) => (
  <Grid>
    {allPeople.map(person => (
      <Person id={person.anchor} key={person.id}>
        <Image fluid={person.image.file && person.image.file.img.fluid} />
        <Col padding="0 10px 50px 10px" alignItems="center">
          {person.position && <Position>{person.position}</Position>}
          <Gap gap="38px" />
          <Row alignItems="baseline" justifyContent="center" width="100%">
            <Degree>{person.titleBefore}</Degree>
            <Gap gap="4px" />
            <Name style={{ whiteSpace: 'nowrap' }}>{person.name}</Name>
            <Gap gap="4px" />
            <Degree>{person.titleAfter}</Degree>
          </Row>
          <Gap gap="36px" />
          <Text textAlign="center" letterSpacing="0.56px">
            <div dangerouslySetInnerHTML={{ __html: person.perex }} />
          </Text>
          {person.contactMail && (
            <>
              <Gap gap="30px" />
              <EmailLink href={`mailto:${person.contactMail}`}>
                » napsat e-mail
              </EmailLink>
            </>
          )}
        </Col>
      </Person>
    ))}
  </Grid>
)

export default PeopleList
