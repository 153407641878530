import React, { Component } from 'react'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import styled, { css } from 'styled-components'
import HintTooltip from './HintTooltip'
import Error from './Error'
import Label from './Label'
import { StyledInput } from './Input'
import { addResponsivity, up, down, between, addFont, colors } from '../../lib/styles'
import { Col, Row, Text, Title, Gap, Link, VH, Button } from '..'
import SMapPlaces from './SMapPlaces'

const DropdownPositioner = styled.div`
  position: relative;
`

const DropdownWrapper = styled.div`
  ${({ theme: { colors }, hide }) => css`
    border-radius: 8px;
    border: solid thin ${colors.checkRadioGrey};
    overflow: hidden;
    background: white;
    margin: 0 2px;
    position: absolute;
    z-index: 2;

    ${hide
    && css`
      display: none;
    `}
  `}
`

const DropdownItem = styled.div`
  ${({ theme: { colors }, active }) => css`
    ${addFont('signika')}
    padding: 8px 15px;
    cursor: pointer;

    &:hover {
      background: ${colors.inputGrey};
    }
  `}
`

const DropdownItemDescription = styled.div`
  ${({ theme: { colors }, active }) => css`
    font-size: 12px;
  `}
`

export class SLocation extends React.Component {
  state = {
    suggestions: [],
    prevInputValue: this.props.initialValue,
    inputValue: this.props.initialValue,
    lastValidValue: this.props.initialValue,
    showSuggestions: false,
  }

  static getDerivedStateFromProps(props, state) {
    // because of lazy load persistent formik is passing address text value
    if (props.initialValue !== state.prevInitialValue) {
      return {
        prevInitialValue: props.initialValue,
        inputValue: props.initialValue,
        lastValidValue: props.initialValue,
      }
    }
    return null
  }

  componentWillUnmount() {
    clearTimeout(this.blurTimeout)
  }

  handleChange = (address) => {
    this.setState({ address })
  }

  handleBlur = (...args) => {
    clearTimeout(this.blurTimeout)
    this.blurTimeout = setTimeout(() => {
      this.props.onBlur(...args)
      this.setState((state) => ({ showSuggestions: false, inputValue: state.lastValidValue }))
    }, 300)
  }

  handleFocus = () => {
    this.setState({ showSuggestions: true })
  }

  handleSelect = (item) => {
    // console.log('item', item)
    this.setState({
      inputValue: item.phrase,
      lastValidValue: item.phrase,
    })

    this.props.onChange({
      address: item.phrase,
      placeId: item.id,
      secondRow: item.secondRow,
      source: item.source,
      latLng: {
        lat: item.latitude,
        lng: item.longitude,
      },
    })
  }

  render() {
    const { error } = this.props
    const { inputValue, showSuggestions } = this.state

    return (
      <SMapPlaces value={inputValue}>
        {({ suggestions, loading }) => {
          const a = 'b'

          return (
            <Col>
              <StyledInput
                error={error}
                onBlur={this.handleBlur}
                value={inputValue}
                onChange={(e) => {
                  this.setState({ inputValue: e.target.value, showSuggestions: true })
                }}
              />
              <DropdownPositioner>
                <DropdownWrapper hide={!showSuggestions}>
                  {loading && <DropdownItem>Nahrávám...</DropdownItem>}
                  {suggestions.map((suggestion) => (
                    <DropdownItem
                      key={suggestion.id}
                      active={suggestion.active}
                      onClick={() => {
                        this.handleSelect(suggestion)
                      }}
                    >
                      {/* iconType: "geo"
                        iconUrl: ""
                        id: 9903382
                        latitude: 50.1458282335335
                        longitude: 13.6611274276519
                        phrase: "Chrášťany 85"
                        poiTypeId: 0
                        secondRow: "Chrášťany, okres Rakovník, Středočeský kraj, Česko"
                        source: "addr"
                        thirdRow: "Adresa"
                        title: "Chrášťany 85" */}
                      <div>{suggestion.phrase}</div>
                      <DropdownItemDescription>{suggestion.secondRow}</DropdownItemDescription>
                    </DropdownItem>
                  ))}
                </DropdownWrapper>
              </DropdownPositioner>
            </Col>
          )
        }}
      </SMapPlaces>
    )
  }
}

const SLocationInput = ({
  field,
  form: { errors, setFieldTouched, setFieldValue, touched, ...rest },
  meta,
  label,
  hint,
  errorName,
  ...props
}) => {
  const handleChange = (value) => {
    // console.log('value', value)
    setFieldTouched(field.name)
    setFieldValue(field.name, value)
    setFieldValue() // sadly this is necessary to trigger persistence
  }
  const handleBlur = (value) => {
    setFieldTouched(field.name)
  }

  console.log('props', props, rest, errors)

  return (
    <Col>
      <Row>
        <Label htmlFor={props.id || field.name}>{label}</Label>
        <HintTooltip hint={hint} />
      </Row>
      <Gap />
      <SLocation
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched[field.name] && errors[field.name]}
        initialValue={field.value && field.value.address}
        // this resets whole component on address string change!
        // key={field.value && field.value.address}
      />
      <Error name={field.name} />
      <Gap gap="16px" />
    </Col>
  )
}

export default SLocationInput
