// config for categories, used e.g. in CoursesBox
export const categories = {
  FIE_STANDARD: {
    label: 'MIU 1 až 3 (Standard)',
    boxTitle: 'MIU 1 až 3',
    description:
      'Kurzy <b>řady Standard</b>  seznámí účastníky s teoretickými základy metody vycházející z Feuersteinova instrumentálního obohacování – od úvodního kurzu <b>Standard 1</b> po navazující (volitelné) <b>Standard 2</b> nebo <b>Standard 3</b>.',
    filters: true,
  },
  FIE_BASIC: {
    label: 'MIU Elementární (Basic)',
    boxTitle: 'MIU Elementární',
    description:
      'Kurzy řady <b>Basic</b> přinesou účastníkům variantu metody pro práci s dětmi předškolního věku nebo s jedinci se zásadnějšími problémy, vycházející z Feuersteinova instrumentálního obohacování.',
  },
  LPAD: {
    label: 'LPAD',
    // subtitle: '(Dynamické vyšetření učebního potenciálu)',
    boxTitle: 'Dynamické vyšetření učebního potenciálu',
    description:
      'Kurz Dynamického vyšetření seznámí účastníky s teoretickými základy i s praktickými postupy a nástroji pro dynamické hodnocení učebního potenciálu.',
    isEvent: true,
  },
  TASTING: {
    label: 'Ochutnávky a semináře',
    boxTitle: 'Ochutnávky a semináře',
    description:
      'Naše jednodenní semináře umožní účastníkům blíže se seznámit s metodami zážitkovou formou (ochutnávky). Nabídne tak absolventům kurzů možnost upevnit si zásady práce s metodou instrumentálního obohacování a uvědomování nebo s metodou LPAD a najít odpovědi na otázky z praxe (inspirační semináře).',
  },
  CONFERENCE: {
    label: 'Konference a další akce',
    boxTitle: 'Konference a další akce',
    hideAboutButton: true,
    isEvent: true,
    description:
      '<b>Naše konference</b> pořádáme s cílem představit účastníkům různá místa a prostředí, do kterých bylo začleněno <b>MIU</b> a dynamická diagnostika učebního potenciálu jako pevná součást každodenního života.',
  },
}

// TODO musí toto být funkce? Nemůže to prostě vrátit pole?
export const getCategoriesKeys = () => Object.keys(categories)

export const categoriesKeysObject = Object.keys(categories).reduce(
  (result, key) => {
    result[key] = key
    return result
  },
  {},
)
