import React from 'react'
import {
  Title,
  Gap,
  Row,
  Col,
  Text,
  Link,
  SEO,
  Page,
  PageContent,
} from '../components'
import injectIntl from '../lib/intl/injectIntl'

// eslint-disable-next-line react/prefer-stateless-function
class Page404 extends React.Component {
  render() {
    return (
      <>
        <SEO title="Nenalezeno / Not found" />
        <Page>
          <PageContent>
            <Col maxWidth="600px" alignSelf="center">
              <Gap gap="150px" />
              <Title.Page color="text">404</Title.Page>
              <Gap gap="24px" />
              <Title.Section gray>Stránka nebyla nalezena</Title.Section>
              <Gap gap="24px" />

              <Col alignItems="center">
                <Gap gap="16px" />

                <Gap gap="150px" />
              </Col>
            </Col>
          </PageContent>
        </Page>
      </>
    )
  }
}

export default injectIntl(Page404)
