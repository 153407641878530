import { ErrorMessage } from 'formik'
import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import {
  addResponsivity,
  up,
  down,
  between,
  addFont,
  colors,
} from '../../lib/styles'

const StyledErrorWrapper = styled.div`
  position: relative;
`
const StyledError = styled.div`
  ${({ theme: { colors }, relative }) => css`
    font-weight: 400;
    font-size: 14px;
    color: ${colors.red};
    position: ${relative ? 'relative' : 'absolute'};
    top: 0;
    text-transform: uppercase;
  `}
`

const Error = ({ name, relative }) => (
  <StyledErrorWrapper>
    <ErrorMessage name={name} component={StyledError} relative={relative} />
  </StyledErrorWrapper>
)

export default Error
