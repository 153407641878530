/**
 * just mapping from constants to pageKeys
 * keys are free, values has to be pageKeys in ./pagesConfig
 * which is either generated /page-name for pages or template filename
 */
exports.links = {
  HOMEPAGE: '/',
  ABOUT: '/about',
  BLOG: '/blog',
  COLLABORATORS: '/collaborators',
  PROJECTS: '/projects',
  METHODS: '/methods',
  METHODS_FEUERSTEIN_LIFE: '/methods/feuerstein',
  METHODS_APPROACH: '/methods/approach',
  METHODS_THEORY: '/methods/theory',
  METHODS_LPAD: '/methods/lpad',
  METHODS_MIU: '/methods/miu',
  METHODS_ENRICHMENT: '/methods/enrichment',
  COURSES: '/courses',
  JOURNEY: '/journey',
  THANKS: '/thanks',
  STYLES: '/styles', // example page
  POST: 'PostTemplate',
  COURSE: 'CourseTemplate',
  PROJECT: 'ProjectTemplate',
}

// ids of the elements
exports.anchors = {
  FOOTER_BOX: 'footer-box',
  COURSES_BOX: 'courses-box',
  COURSE_DETAILS: 'courses-details',
  COLLABORATORS_MAP: 'collaborators-map',
}
