import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { darken, lighten } from 'polished'
import HintTooltip from './HintTooltip'
import Error from './Error'
import Label from './Label'
import { addResponsivity, up, down, between, addFont, colors } from '../../lib/styles'
import { Col, Row, Text, Title, Gap, Link, VH, Button } from '..'

const StyledRadioWrapper = styled.div`
  ${({ theme: { colors }, error, checked }) => css`
    background: ${colors.inputGrey};
    border-radius: 8px;
    padding: 16px 11px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    border: solid 2px transparent;
    margin-bottom: 8px;

    &:hover {
      /* background: ${darken(0.02, colors.inputGrey)}; */
    }
    &:last-child {
      margin-bottom: 0;
    }

    ${error
    && css`
      border-color: ${colors.red};
    `}

    ${checked
    && css`
    /* border-color: ${colors.lightOrange}; */
    font-weight: 700;
    /* background: ${darken(0.08, colors.inputGrey)}; */
    &:hover {
      /* background: ${darken(0.08, colors.inputGrey)}; */
    }
  `}
  `}
`
const StyledRadio = styled.div`
  ${({ theme: { colors }, error, checked }) => css`
    background: ${colors.inputGrey};
    background: white;
    border-radius: 200px;
    border: solid 2px ${colors.checkRadioGrey};
    width: 24px;
    height: 24px;
    position: relative;

    ${error
    && css`
      border-color: ${colors.red};
    `}

    ${checked
    && css`
      /* background: white;
     */
      &:after {
        display: block;
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 30px;
        content: '';
        background: ${colors.orange};
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    `}
  `}
`

const RadioGroup = ({
  field: { name, value, onChange, onBlur, ...rest },
  form: { errors, setFieldTouched, setFieldValue, touched },
  meta,
  label,
  hint,
  options,
  ...props
}) => {
  const handleChange = value => {
    setFieldTouched(name)
    setFieldValue(name, value)
    setFieldValue() // sadly this is necessary to trigger persistence
    console.log('value', value)

  }

  return (
    <Col>
      <Row>
        <Label>{label}</Label>
        <HintTooltip hint={hint} />
      </Row>
      <Gap />
      {options.map(opt => (
        <StyledRadioWrapper
          checked={opt.value === value}
          key={opt.value}
          onClick={() => handleChange(opt.value)}
        >
          <StyledRadio checked={opt.value === value} />
          <Gap />
          {opt.label}
        </StyledRadioWrapper>
      ))}
      {touched[name] && errors[name] ? <div>{errors[name]}</div> : null}
      {/* <ErrorMessage name={name} component={StyledError} /> */}
    </Col>
  )
}

export default RadioGroup
