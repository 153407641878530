import React, { Component } from 'react'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import styled, { css } from 'styled-components'
import HintTooltip from './HintTooltip'
import Error from './Error'
import Label from './Label'
import { StyledInput } from './Input'
import { addResponsivity, up, down, between, addFont, colors } from '../../lib/styles'
import { Col, Row, Text, Title, Gap, Link, VH, Button } from '..'

const DropdownPositioner = styled.div`
  position: relative;
`

const DropdownWrapper = styled.div`
  ${({ theme: { colors }, hide }) => css`
    border-radius: 8px;
    border: solid thin ${colors.checkRadioGrey};
    overflow: hidden;
    background: white;
    margin: 0 2px;
    position: absolute;
    z-index: 2;

    ${hide
    && css`
      display: none;
    `}
  `}
`

const DropdownItem = styled.div`
  ${({ theme: { colors }, active }) => css`
    ${addFont('signika')}
    background: ${active && colors.inputGrey};
    padding: 8px 15px;
    cursor: pointer;
`}
`

export class Location extends React.Component {
  state = { address: '' }

  componentDidMount() {
    const { initialValue } = this.props
    if (initialValue) {
      this.setState({ address: initialValue })
    }
  }

  componentWillReceiveProps(nextProps) {
    const { initialValue } = this.props
    if (initialValue !== nextProps.initialValue && !this.state.address) {
      this.setState({ address: nextProps.initialValue })
    }
  }

  handleChange = (address) => {
    this.setState({ address })
  }

  handleSelect = (address) => {
    let result
    geocodeByAddress(address)
      .then((results) => {
        result = results[0]
        return getLatLng(result)
      })
      .then((latLng) => {
        console.log('Success', latLng, address, result)
        this.props.onChange({
          address,
          latLng,
          placeId: result.place_id,
        })
        this.setState({ address })
      })
      .catch((error) => console.error('Error', error))
  }

  handleBlur = () => {
    // this resets shown text to last formik saved text
    this.setState({ address: this.props.initialValue })
    // if user clicks on select, it will be replaced. If not - we show last stored value
  }

  render() {
    const { error, initialValue } = this.props
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        debounce={450}
        searchOptions={{
          types: ['geocode'],
          // location: {
          //   lat: 50.043298,
          //   lng: 14.24873,
          // },
          // location: new window.google.maps.LatLng(50.043298, 14.24873),
          // radius: 300000000000,
          componentRestrictions: {
            country: 'cz',
          },
        }}
        shouldFetchSuggestions={this.state.address.length > 3}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          // console.log('getInputProps', getInputProps({}))
          const inputProps = {
            ...getInputProps({}),
          }
          // inputProps.onBlurOriginal = inputProps.onBlur
          // inputProps.onBlur = (...args) => {
          //   inputProps.onBlurOriginal(args)
          //   this.props.onBlur()
          // }
          return (
            <Col>
              <StyledInput
                {...inputProps}
                error={error}
                onBlur={(...args) => {
                  this.handleBlur()
                  this.props.onBlur(...args)
                  inputProps.onBlur(...args)
                }}
              />
              <DropdownPositioner>
                <DropdownWrapper hide={!suggestions || !suggestions.length}>
                  {loading && <DropdownItem>Nahrávám...</DropdownItem>}
                  {suggestions.map((suggestion) => (
                    <DropdownItem
                      active={suggestion.active}
                      {...getSuggestionItemProps(suggestion)}
                    >
                      <span>{suggestion.description}</span>
                    </DropdownItem>
                  ))}
                </DropdownWrapper>
              </DropdownPositioner>
            </Col>
          )
        }}
      </PlacesAutocomplete>
    )
  }
}

const LocationInput = ({
  field,
  form: { errors, setFieldTouched, setFieldValue, touched },
  meta,
  label,
  hint,
  ...props
}) => {
  const handleChange = (value) => {
    // console.log('value', value)
    setFieldTouched(field.name)
    setFieldValue(field.name, value)
    setFieldValue() // sadly this is necessary to trigger persistence
  }
  const handleBlur = (value) => {
    setFieldTouched(field.name)
  }

  return (
    <Col>
      <Row>
        <Label htmlFor={props.id || field.name}>{label}</Label>
        <HintTooltip hint={hint} />
      </Row>
      <Gap />
      <Location
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched[field.name] && errors[field.name]}
        initialValue={field.value && field.value.address}
      />
      <Error name={field.name} />
      <Gap gap="16px" />
    </Col>
  )
}

export default LocationInput
