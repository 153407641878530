// external imports
import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'

// helpers
import { up, addResponsivity } from '../lib/styles'
import { extractNodes, extractNodesFromData } from '../lib/helpers'
import injectIntl from '../lib/intl/injectIntl'
import withExtraction from '../lib/withExtraction'

// components
import SEO from '../components/SEO'
import {
  Title,
  Gap,
  Row,
  Col,
  Link,
  Text,
  RH,
  Button,
  HeaderBar,
  Testimonials,
} from '../components'
import { Page, PageContent } from '../components/Page'
import CoursesBox from '../components/CoursesBox'
import CourseDetailBox from '../components/CourseDetailBox'
import { getCategoriesKeys } from '../courses'
import headerBarImageSrc from '../data/images/courses-top.png'
import { isCs, isSk } from '../lib/appVersions'

const groupCourseTypesByCategory = (allCourseTypes = []) => {
  const categories = {}
  allCourseTypes.forEach(type => {
    if (!categories[type.category]) {
      categories[type.category] = []
    }
    categories[type.category].push(type)
  })
  return categories
}

const categoriesKeys = getCategoriesKeys()

const TopImage = styled.img`
  padding: 53px 0 56px 0;
`

class Courses extends React.Component {
  state = {
    activeCategoryKey: categoriesKeys[0],
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search)
    const cat = urlParams.get('active')
    if (categoriesKeys.includes(cat)) {
      this.setActiveCategory(cat)
    }
  }

  setActiveCategory = activeCategoryKey => {
    this.setState({ activeCategoryKey })
  }

  render() {
    const { activeCategoryKey } = this.state
    const {
      location: { pathname },
      intl: { language, headless },
      data: { allCourseTypes },
    } = this.props

    // console.log('this.props', this.props)
    // const headless = search === '?headless'

    const coursesTypesByCategories = groupCourseTypesByCategory(allCourseTypes)

    const activeCategory = coursesTypesByCategories[activeCategoryKey]

    return (
      <>
        <CoursesBox
          coursesTypesByCategories={coursesTypesByCategories}
          setActiveCategory={this.setActiveCategory}
          activeCategoryKey={activeCategoryKey}
          isHeadless
        />

        {activeCategoryKey &&
          activeCategory &&
          activeCategory.map((courseType, index) => (
            <>
              <Gap gap="60px" />
              <Gap gap="0" id={Link.anchors.COURSE_DETAILS} />
              <CourseDetailBox
                courseType={courseType}
                key={courseType.id}
                setActiveCategory={this.setActiveCategory}
                isHeadless
              />
            </>
          ))}
      </>
    )
  }
}

// {Object.keys(coursesTypesByCategories).map(categoryKey => (
//   <div>
//     categoryKey: {categoryKey} <br />
//     {/* popisky kategorii dat nekam do souboru */}
//     {coursesTypesByCategories[categoryKey].map(type => (
//       <div>
//         type : {type.name} {type.longName}
//         {type.courseTerms.map(t => t.fromToDesc)}
//       </div>
//     ))}
//   </div>
// ))}

export default injectIntl(withExtraction(Courses))

export const pageQuery = graphql`
  query {
    ks {
      allCourseTypes {
        ...CourseTypeFragment
        ...CourseTypeNestedFragment
      }
    }
  }
`

// this fn is run always, both domains, even outside of scope of this page

function sendHeightToParent() {
  const height = document.documentElement.scrollHeight
  window?.parent?.postMessage(
    { height, coursesHeadless: true },
    'https://atc-sk.wx.virtii.com'
  )
  window?.parent?.postMessage(
    { height, coursesHeadless: true },
    'http://127.0.0.1:8000'
  )
  console.log('sendHeightToParent', height)
}

if (typeof window !== 'undefined') {
  window.onload = sendHeightToParent
  window.onresize = sendHeightToParent
}
