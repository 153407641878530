// external imports
import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'

// helpers
import { up, addResponsivity } from '../lib/styles'
import { extractNodes, extractNodesFromData } from '../lib/helpers'
import injectIntl from '../lib/intl/injectIntl'
import withExtraction from '../lib/withExtraction'
import T from '../lib/intl/T'

// components
import SEO from '../components/SEO'
import {
  Title,
  Gap,
  Row,
  Col,
  Link,
  Text,
  RH,
  Button,
  HeaderBar,
  Testimonials,
  Page,
  PageContent,
  PostList,
} from '../components'
import headerBarImageSrc from '../data/images/blog-top.png'

let savedState = null

//     <Link pageKey={Link.POST} slugs={post.slugs} preferLang="en">
//       v EN
//     </Link>
//     |
//     <Link pageKey={Link.POST} slugs={post.slugs} preferLang="cs">
//       v cs
//     </Link>

const LIMIT = 9

class Blog extends React.Component {
  state = {
    limit: LIMIT,
  }

  componentDidMount() {
    if (savedState) {
      this.setState({ ...savedState })
    }
  }

  componentWillUnmount() {
    savedState = { ...this.state }
  }

  onLoadMoreClick = () => {
    const { limit } = this.state
    this.setState({ limit: limit + LIMIT })
  }

  render() {
    const {
      location: { pathname },
      intl: { language, t },
    } = this.props

    const { allPosts } = this.props.data

    const { limit } = this.state

    const filteredPosts = allPosts.slice(0, limit)

    return (
      <>
        <SEO title={t('Aktuality')} pathname={pathname} />
        <HeaderBar>
          <HeaderBar.Content>
            <HeaderBar.Title>
              <T>Aktuality</T>
            </HeaderBar.Title>
            <HeaderBar.SubTitle>
              <T>AKTUÁLNÍ DĚNÍ, POZVÁNKY NA AKCE A KONFERENCE</T>
            </HeaderBar.SubTitle>
          </HeaderBar.Content>
          {/* <HeaderBar.ImageWrapper
            padding="0 100px 0 20px"
            mobileMaxWidth="340px"
          >
            <img src={headerBarImageSrc} alt="" />
          </HeaderBar.ImageWrapper> */}
        </HeaderBar>

        <Page grey>
          <PageContent>
            <Col alignItems="center">
              <Gap gap="82px" mobileGap="60px" />
              <PostList posts={filteredPosts} />
              {limit < allPosts.length && (
                <>
                  <Gap gap="108px" mobileGap="60px" />
                  <Button.PrimaryOrange onClick={this.onLoadMoreClick}>
                    <T>další aktuality</T>
                  </Button.PrimaryOrange>
                </>
              )}
              <Gap gap="108px" mobileGap="60px" />
            </Col>
          </PageContent>
        </Page>
        <Page>
          <Gap gap="120px" mobileGap="60px" />
          <PageContent>
            <Testimonials />
          </PageContent>
        </Page>
        <Gap gap="198px" mobileGap="97px" />
      </>
    )
  }
}

export default injectIntl(withExtraction(Blog))

export const pageQuery = graphql`
  query {
    ks {
      allPosts(where: { isPublished: true }, sortBy: publishedAt_DESC) {
        ...PostFragment
      }
    }
  }
`
