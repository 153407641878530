import { applyLang, applyLangSingle } from './intl'
import moment from 'moment'
/**
 * Almost all of our helper functions goes here
 * Style helpers go to ./style.js
 */

export const formatDate = (dateToFormat) => moment(dateToFormat).format('D. M. YYYY')

// extract nodes from edges, if no data returns empty array like in rest
// (graphql returns null if nothing is found in db...)
export const extractNodes = data =>
  (data && data.edges && data.edges.map(e => e.node)) || []

// extract all edges - nodes data from all data results from DB / graphql
export const extractNodesFromData = (propsData = {}) => {
  const transformed = { ...propsData }
  Object.keys(propsData).forEach(key => {
    // some data does not have to have edges, skip them
    if (propsData[key].edges) {
      transformed[key] = propsData[key].edges.map(e => e.node)
    }
  })

  return transformed
}

export const withSameTags = (arrayToFilter = [], tags = []) =>
  arrayToFilter.filter(
    p =>
      p.tags &&
      p.tags.some(tag => tags && tags.find(tag2 => tag2._id === tag._id)),
  )

// extract all edges - nodes data from all data results from DB / graphql
export const extractKsFromData = (propsData = {}) => {
  const transformed = { ...propsData, ...propsData.ks }
  return transformed
}

// extract all edges - nodes data from all data results from DB / graphql
// AND apply lang extractions
export const extractDataAndApplyLangs = (propsData = {}, lang) => {
  if (!lang) {
    console.error('pozor, pri extrakci neni nastaven jazyk!')
  }
  const transformedKs = { ...propsData.ks }
  Object.keys(propsData.ks || {}).forEach(key => {
    // if its single model only
    if (typeof propsData.ks[key] === 'object' && propsData.ks[key].modelName) {
      // console.log('propsData.ks[key]', propsData.ks[key])
      transformedKs[key] = applyLangSingle(propsData.ks[key], lang)
    } else if (
      typeof propsData.ks[key] === 'object' &&
      Array.isArray(propsData.ks[key]) &&
      propsData.ks[key][0] &&
      propsData.ks[key][0].modelName
    ) {
      // console.log('propsData.ks[key]', propsData.ks[key])
      transformedKs[key] = applyLang(propsData.ks[key], lang)
    }
  })

  return { ...propsData, ...transformedKs }
}
