import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import T from '../lib/intl/T'

import SEO from '../components/SEO'
import {
  Title,
  Gap,
  Row,
  Col,
  Link,
  Text,
  RH,
  Button,
  HeaderBar,
  SubHeaderBar,
  Testimonials,
  Page,
  PageContent,
  ProjectList,
} from '../components'

import injectIntl from '../lib/intl/injectIntl'
import withExtraction from '../lib/withExtraction'

import headerBarImageSrc from '../data/images/o-projektech-top.png'

let savedState = null
const LIMIT = 9

class Projects extends React.Component {
  state = {
    limit: LIMIT,
  }

  componentDidMount() {
    if (savedState) {
      this.setState({ ...savedState })
    }
  }

  componentWillUnmount() {
    savedState = { ...this.state }
  }

  onLoadMoreClick = () => {
    const { limit } = this.state
    this.setState({ limit: limit + LIMIT })
  }

  render() {
    const {
      location: { pathname },
      intl: { language, t },
      data: { allProjects, headerBarImage },
    } = this.props
    const { limit } = this.state

    const filteredProjects = allProjects.slice(0, limit)

    return (
      <>
        <SEO title={t('O projektech')} pathname={pathname} />
        <HeaderBar>
          <HeaderBar.Content>
            <HeaderBar.Title>
              <T>O projektech</T>
            </HeaderBar.Title>
            <HeaderBar.SubTitle>
              <T>Informace o získaných projektech</T>
            </HeaderBar.SubTitle>
          </HeaderBar.Content>
          {/* <HeaderBar.ImageWrapper
            padding="0 20px 0 20px"
            mobileMaxWidth="340px"
            width="100%"
            maxWidth="632px"
          >
            <Img fluid={headerBarImage.image.fluid} />
          </HeaderBar.ImageWrapper> */}
        </HeaderBar>

        <SubHeaderBar contentMaxWidth="1200px">
          <SubHeaderBar.Text>
            <T raw>
              Akreditované tréninkové centrum metod pro osobní rozvoj se na
              projektech podílelo již od svého vzniku. Většinou zastávalo roli
              klíčového partnera, který zajišťoval proškolení účastníků a další
              navazující odborné aktivity. Kromě toho jsme získávali první
              zkušenosti jako příjemci „menších" projektů.
            </T>
          </SubHeaderBar.Text>
          <Gap gap="60px" mobileGap="48px" />
          <SubHeaderBar.Text>
            <T raw>
              {
                'V roce 2020 jsme se rozhodli výrazněji posílit naší pozici příjemce projektů a do struktury ATC jsme přidali <strong>projektové oddělení.</strong> S metodou tak díky soukromým i státním dárcům i nadále míříme do různých oblastí nejen v České republice, ale i do zahraničí.'
              }
            </T>
          </SubHeaderBar.Text>
        </SubHeaderBar>

        <Page white>
          <PageContent>
            <Col alignItems="center">
              <Gap gap="82px" mobileGap="60px" />
              <ProjectList projects={filteredProjects} />
              {limit < allProjects.length && (
                <>
                  <Gap gap="108px" mobileGap="60px" />
                  <Button.PrimaryOrange onClick={this.onLoadMoreClick}>
                    <T>další aktuality</T>
                  </Button.PrimaryOrange>
                </>
              )}
              <Gap gap="108px" mobileGap="60px" />
            </Col>
          </PageContent>
        </Page>
        {language === 'cs' && (
          <Page>
            <Gap gap="120px" mobileGap="60px" />
            <PageContent>
              <Testimonials />
            </PageContent>
          </Page>
        )}
        <Gap gap="198px" mobileGap="97px" />
      </>
    )
  }
}

export default injectIntl(withExtraction(Projects))

export const pageQuery = graphql`
  query {
    ks {
      allProjects(where: { isPublished: true }, sortBy: createdAt_DESC) {
        ...ProjectFragment
      }
    }
    headerBarImage: file(relativePath: { eq: "images/o-projektech-top.png" }) {
      image: childImageSharp {
        fluid(maxWidth: 612, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
