import NotoSansBold from '../data/fonts/NotoSans-Bold.ttf'
import NotoSansRegular from '../data/fonts/NotoSans-Regular.ttf'
import NotoSansLight from '../data/fonts/NotoSans-Light.ttf'
import NotoSansExtraLight from '../data/fonts/NotoSans-ExtraLight.ttf'
import NotoSansExtraBold from '../data/fonts/NotoSans-ExtraBold.ttf'

export default {
  NotoSansLight,
  NotoSansBold,
  NotoSansRegular,
  NotoSansExtraLight,
  NotoSansExtraBold,
}
