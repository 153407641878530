// external imports
import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../../components/SEO'
import { Text, MethodPageLayout, Button, Gap } from '../../components'
import { links } from '../../links'

class Theory extends React.Component {
  componentDidMount() {}

  render() {
    const {
      location: { pathname },
      data: { image1 },
    } = this.props
    const images = [image1]
    return (
      <>
        <SEO title="Teoretická východiska" pathname={pathname} />
        <MethodPageLayout>
          <MethodPageLayout.Title>Teoretická východiska</MethodPageLayout.Title>
          <MethodPageLayout.TitleGap />
          <MethodPageLayout.Content images={images}>
            <Text.Wide>
              Prof. Reuven Feuerstein si v průběhu své pedagogické práce s dětmi
              všiml zajímavého jevu. Děti, které nepodávaly ve škole dostatečné
              výkony, se při vykonávání domácích prací nebo při hře projevovaly
              naprosto standardně. Při dalším zkoumání došel Feuerstein k
              závěru, že děti, které nejsou schopny učit se ze zkušeností nebo
              při školní výuce, trpí deficitem v kognitivní oblasti - nenaučily
              se myslet v souvislostech. Co to představuje v praxi? Zejména
              slabou schopnost poznatky strukturovat a organizovat. Základní
              charakteristikou oslabených kognitivních funkcí je impulzivnost v
              jednání a myšlení, to, že jsou přijímány jen jednotlivé informace,
              v neposlední řadě pak neschopnost poučit se ze svých chyb. Děti s
              nimiž Feuerstein pracoval, byly neúspěšné ne proto, že by nebyly
              dostatečně inteligentní, ale protože některé jejich poznávací
              funkce nebyly dostatečně rozvinuty. Právě toto zjištění
              vysvětlovalo obtíže, které děti měly při plánování práce nebo při
              přemýšlení nad strategii, kterou úkol vyžaduje, o flexibilitě
              změny strategie, když se neosvědčí, popř.když se změní původní
              zadání úkolu, ani nemluvě. Své bádání shrnul v teorii strukturální
              kognitivní modifikovatelnosti, která tvořila základní stavební
              kámen jeho další práce – diagnostické baterie LPAD a programu
              Instrumentálního obohacování.
            </Text.Wide>
            <Gap gap="40px" />
            <Button.SecondaryOrange
              link={{ pageKey: links.METHODS }}
              iconLeft="arrowLeft"
            >
              Zpátky na rozcestník
            </Button.SecondaryOrange>
          </MethodPageLayout.Content>
        </MethodPageLayout>
      </>
    )
  }
}

export default Theory

export const pageQuery = graphql`
  query {
    image1: file(relativePath: { eq: "images/methods/theory1.jpeg" }) {
      image: childImageSharp {
        fluid(maxWidth: 452, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
