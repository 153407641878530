/**
 * This provides this page language settings and helpers to any component
 *
 */

import React from 'react'
import { t, languages, defaultLanguage, generateLink } from '.'
import IntlContext from './IntlContext'

export default WrappedComponent =>
  class extends React.Component {
    static contextType = IntlContext

    translateInThisLang = (key, lang = this.context.language) => t(key, lang)

    render() {
      const injectedProps = {
        // helper translate fn
        t: this.translateInThisLang,
        // this lang app config
        languages,
        defaultLanguage,
        generateLink,
        // intl context, with provider state and gatsby-node page lang settings
        ...this.context,
      }

      return <WrappedComponent {...this.props} intl={injectedProps} />
    }
  }
