import React from 'react'
import styled, { css } from 'styled-components'

import { Col, Button, Text, Gap } from '.'
import { up } from '../lib/styles'

import squareSrc from '../data/images/icons/square.svg'
import hatSrc from '../data/images/icons/university-hat.svg'
import findPersonSrc from '../data/images/icons/find-person.svg'
import pencilSrc from '../data/images/icons/pencil.svg'
import connectedSrc from '../data/images/icons/connected-persons.svg'
import featherSrc from '../data/images/icons/feather.svg'
import bookSrc from '../data/images/icons/book.svg'
import { enhanceContent, replaceShortCodes } from '../lib/post'

const Wrapper = styled.div`
  ${({ orange, theme: { colors }, even }) => css`
    position: relative;
    top: 0;
    max-width: 296px;
    width: auto;
    margin: 0 auto;
    /* height: 521px; */
    box-shadow: 2px 3px 81px rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    display: flex;
    flex-direction: column;

    ${!even &&
      css`
        ${up('desktop')} {
          &:nth-child(odd) {
            top: 40px;
          }
        }
      `}

    ${Header} {
      background-color: ${orange ? colors.orange : colors.green};
      min-height: ${orange ? '111px' : '130px'};
    }
    &:nth-child(even) ${Header} {
      background-color: ${orange ? colors.darkOrange : colors.darkGreen};
    }
  `}
`

const Grid = styled.div`
  ${({ desktopPosition = 'static' }) => css`
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    width: 100%;
    margin: auto;
    z-index: 1;
    position: static;

    ${up('mobile')} {
      grid-row-gap: 40px;
      max-width: 640px;
      grid-template-columns: repeat(2, 1fr);
    }
    ${up('desktop')} {
      max-width: 100%;
      grid-template-columns: repeat(4, 1fr);
      position: ${desktopPosition};
    }
  `}
`

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9ad3d;
  font-size: 15px;
  font-weight: 700;
  line-height: 25px;
  text-transform: uppercase;
  letter-spacing: 1.8px;
  color: #ffffff;
  text-align: center;
  padding: 27px 28px;
`

const Card = props => <Wrapper {...props} />

Card.Header = Header
Card.Content = props => (
  <Col
    padding="22px 32px 50px"
    alignItems="center"
    justifyContent="space-evenly"
    grow={1}
    {...props}
  />
)

// TODO - nejde 100% šířka udělat lépe? bez přímého stylování linku?
Card.Button = ({ link, ...props }) => (
  <Button.SecondaryOrange
    paddingLeft="20px"
    paddingRight="20px"
    alignSelf="stretch"
    width="100%"
    link={{
      ...link,
      style: { width: '100%' },
    }}
    {...props}
  />
)

Card.Text = props => (
  <Text
    letterSpacing="0.75px"
    textAlign="center"
    lineHeight="22px"
    margin="12px 0 32px"
    {...props}
  />
)

Card.TextGap = props => <Gap gap="20px" {...props} />

Card.SquareIcon = props => <img src={squareSrc} alt="" {...props} />
Card.HatIcon = props => <img src={hatSrc} alt="" {...props} />
Card.FindPersonIcon = props => <img src={findPersonSrc} alt="" {...props} />
Card.PencilIcon = props => <img src={pencilSrc} alt="" {...props} />
Card.ConnectedPersonsIcon = props => (
  <img src={connectedSrc} alt="" {...props} />
)

Card.FeatherIcon = props => <img src={featherSrc} alt="" {...props} />
Card.BookIcon = props => <img src={bookSrc} alt="" {...props} />
Card.Grid = Grid

export const RichTextContent = ({ children }) => {
  if (typeof children !== 'string') {
    console.log('RichTextContent children is not string', children)
    return children
  }

  const text = replaceShortCodes(children)

  const contentReactElement = enhanceContent({
    content: text,
    contentImages: [],
  })

  return <>{contentReactElement}</>
}

export default Card
