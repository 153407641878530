import React from 'react'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import { Title } from '.'
import { up } from '../lib/styles'

const PostThumbnail = styled.div`
  box-shadow: 2px 3px 81px rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  max-width: 397px;
  width: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  position: relative;
`

PostThumbnail.Content = styled.div`
  padding: 34px 36px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  text-align: center;
`

PostThumbnail.Title = props => (
  <Title
    fontSize="33px"
    lineHeight="40px"
    letterSpacing="1.23px"
    mobileFontSize="25px"
    mobileLineHeight="30px"
    as="h2"
    {...props}
  />
)

PostThumbnail.Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 42px;
  grid-row-gap: 50px;
  width: 100%;
  margin: auto;

  ${up('mobile')} {
    grid-template-columns: repeat(2, 1fr);
    max-width: 836px;
  }
  ${up('desktop')} {
    max-width: 100%;
    grid-template-columns: repeat(3, 1fr);
  }
`

PostThumbnail.Image = styled(Img)`
  max-width: 100%;
  width: 100%;
`

export default PostThumbnail
