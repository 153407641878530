// external imports
import React from 'react'
import moment from 'moment'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'

// helpers
import { up, addResponsivity } from '../lib/styles'
import {
  extractNodes,
  extractNodesFromData,
  extractNodesFromDataApplyLangs,
  extractDataAndApplyLangs,
  withSameTags,
  formatDate,
} from '../lib/helpers'
import injectIntl from '../lib/intl/injectIntl'
import { enhanceContent, replaceShortCodes } from '../lib/post'
import withExtraction from '../lib/withExtraction'

// components
import {
  Title,
  Gap,
  Row,
  Col,
  SEO,
  Link,
  Text,
  RH,
  Listing,
  ContactFormSection,
  WaveImage,
  RoundedImage,
  RoundWrapper,
  HeaderBar,
  Page,
  PageContent,
  Testimonials,
  Line,
  Button,
  PostList,
} from '../components'
import T from '../lib/intl/T'
import headerBarImageSrc from '../data/images/blog-top.png'

// TODO
// p nahradit za div a ten nahradit za custom componentu :!!!!
const PostContent = styled.div`
  ${({ theme: { colors } }) => css`
    & > .p {
      margin-bottom: 16px;
      letter-spacing: 0.75px;
    }
    & h2, & h3 {
      font-size: 33px;
      font-weight: 100;
      line-height: 40px;
      margin: 0;
      padding: 0;
      margin-top: 32px;
      margin-bottom: 16px;
    }
    & a {
      color: ${colors.green};

      &:hover {
        color: ${colors.darkGreen};
        text-decoration: none;
      }
    }
  `}
`

const CONTENT_WIDTH = '768px'

class PostTemplate extends React.Component {
  componentDidMount() {}

  render() {
    const {
      location: { pathname },
      intl: { language, t },
    } = this.props

    // console.log('pathname', pathname, language)

    // console.log('(this.props.data', this.props.data)
    const { allPosts, post, contentImages } = this.props.data

    // console.log('contentImages', contentImages)

    const contentReactElement = enhanceContent({
      content: replaceShortCodes(post.content || ''),
      contentImages: contentImages.nodes,
    })

    // console.log('      allPosts,post,', allPosts, post)
    // const contentReactElement = enhanceContent({
    //   content: post.content,
    //   contentImages,
    //   procedures,
    // })

    const filteredPosts = allPosts
      .filter(({ id }) => id !== post.id)
      .slice(0, 3)

    return (
      <>
        <SEO
          isArticle
          title={`${post.title} · ${t('blog - meta title')}`}
          pathname={pathname}
          description={post.metaDesc || post.perex}
          // image={
          //   post.postImage && post.postImage.filename.main.fluid.originalImg
          // }
        />
        <HeaderBar>
          <HeaderBar.Content>
            <HeaderBar.Title>
              <T>Aktuality</T>
            </HeaderBar.Title>
            <HeaderBar.SubTitle>
              <T>AKTUÁLNÍ DĚNÍ, POZVÁNKY NA AKCE A KONFERENCE</T>
            </HeaderBar.SubTitle>
          </HeaderBar.Content>
          {/* <HeaderBar.ImageWrapper
            padding="0 100px 0 20px"
            mobileMaxWidth="340px"
          >
            <img src={headerBarImageSrc} alt="" />
          </HeaderBar.ImageWrapper> */}
        </HeaderBar>
        <Page grey>
          <PageContent>
            <Gap gap="78px" mobileGap="60px" />
            <Row
              width="100%"
              alignItems="flex-start"
              mobileAlignItems="center"
              responsive
            >
              <Col maxWidth={CONTENT_WIDTH} width="100%">
                <Title.Section>{post.title}</Title.Section>
                <Gap gap="48px" />
                <Text letterSpacing="2.25px">
                  <T>PUBLIKOVÁNO</T> {formatDate(post.publishedAt)}
                </Text>
                <Gap gap="24px" />
              </Col>
            </Row>
            <Row
              width="100%"
              alignItems="flex-start"
              mobileAlignItems="center"
              responsive
            >
              <Col maxWidth={CONTENT_WIDTH} width="100%">
                <PostContent>
                  <Text>{contentReactElement}</Text>
                </PostContent>
              </Col>
              <Gap gap="62px" />
              <Col width="100%" maxWidth="452px">
                <Img fluid={post.image.file && post.image.file.img.fluid} />
              </Col>
            </Row>
            <Gap gap="104px" mobileGap="60px" />
            <Line />
            <Gap gap="104px" mobileGap="45px" />
            <Col mobileAlignItems="center" alignItems="flex-start" bp="desktop">
              <Title.Section>
                <T>Další aktuality</T>
              </Title.Section>
            </Col>
            <Gap gap="54px" mobileGap="45px" />
            <PostList posts={filteredPosts} />

            <Col alignItems="center">
              <Gap gap="108px" mobileGap="60px" />
              <Button.PrimaryOrange link={{ pageKey: Link.BLOG }}>
                <T>další aktuality</T>
              </Button.PrimaryOrange>
              <Gap gap="108px" mobileGap="60px" />
            </Col>
          </PageContent>
        </Page>

        <Page>
          <Gap gap="142px" mobileGap="60px" />
          <PageContent>
            <Testimonials />
          </PageContent>
        </Page>
        <Gap gap="198px" mobileGap="97px" />
      </>
    )
  }
}

export default injectIntl(withExtraction(PostTemplate))

export const pageQuery = graphql`
  query($id: ID = "") {
    ks {
      post: Post(where: { id: $id }) {
        ...PostFragment
      }
      allPosts(where: { isPublished: true }, sortBy: publishedAt_DESC) {
        ...PostFragment
      }
    }
    contentImages: allFile(filter: {sourceInstanceName: {eq: "uploads-images"}}) {
      nodes {
        ...PostContentImage
        relativePath
      }
    }

    # contentImages: allFile(
    #   filter: { relativePath: { regex: "/^uploaded-image-/" } }
    # ) {
    #   edges {
    #     node {
    #       ...PostContentImage
    #       relativePath
    #     }
    #   }
    # }
  }
`
