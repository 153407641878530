import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import { addResponsivity, up, down, between, addFont } from '../lib/styles'
import { Col, Row, Text, Title, Gap, Link } from '.'

const BP = 860

const ImgWrapper = styled.div`
  ${({ theme: { colors } }) => css`
    width: 160px;
    height: 160px;
    ${up(BP)} {
      width: 200px;
      height: 200px;
    }
  `}
`

const StyledImg = styled(Img)`
  width: 160px !important;
  height: 160px !important;
  ${up(BP)} {
    width: 200px !important;
    height: 200px !important;
  }
`

class Review extends Component {
  render() {
    const { text, name, image } = this.props
    return (
      <Row maxWidth="460px" responsive bp={500} alignItems="center">
        <ImgWrapper>
          <StyledImg fixed={image} />
        </ImgWrapper>
        <Gap gap="24px" />
        <Col>
          <Text fontSize="18px" lineHeight="24px">
            {text}
          </Text>
          <Gap gap="8px" />
          <Text fontSize="18px" lineHeight="24px" fontWeight="700">
            {name}
          </Text>
        </Col>
      </Row>
    )
  }
}

Review.propTypes = {}

export default Review
