// external imports
import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'

// helpers
import { up, addResponsivity } from '../lib/styles'
import { extractNodes, extractNodesFromData } from '../lib/helpers'
import injectIntl from '../lib/intl/injectIntl'
import withExtraction from '../lib/withExtraction'

// components
import SEO from '../components/SEO'
import {
  Title,
  Gap,
  Row,
  Col,
  Link,
  Text,
  RH,
  Button,
  HeaderBar,
} from '../components'
import { Page, PageContent } from '../components/Page'

import headerBarImageSrc from '../data/images/courses-top.png'
import { categories } from '../courses'
import T from '../lib/intl/T'
import { t } from '../lib/intl'

class Thanks extends React.Component {
  state = {
    label: 'Úspěch',
    subtitle: 'Přihláška na kurz',
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search)
    const categoryKey = urlParams.get('category')

    let label = 'Úspěch'
    let subtitle = 'Přihláška na kurz'
    if (categoryKey) {
      const cat = categories[categoryKey]
      if (cat) {
        label = cat.label
        if (cat.isEvent) {
          subtitle = 'Přihlášení na akci'
        }
      }
    }

    this.setState({
      label,
      subtitle,
    })
  }

  render() {
    const {
      location: { pathname },
      intl: { language, headless },
      data: { allCollaborators },
    } = this.props

    const { label, subtitle } = this.state

    return (
      <>
        <SEO title={t('Děkujeme')} pathname={pathname} />
        {!headless && (
          <HeaderBar>
            <HeaderBar.Content>
              <HeaderBar.Title>{label}</HeaderBar.Title>
              <HeaderBar.SubTitle>{subtitle}</HeaderBar.SubTitle>
            </HeaderBar.Content>
            <HeaderBar.ImageWrapper mobileMaxWidth="450px">
              <img src={headerBarImageSrc} alt="" />
            </HeaderBar.ImageWrapper>
          </HeaderBar>
        )}

        <Page grey>
          <PageContent>
            <Col alignItems="center">
              <Gap gap="60px" />
              <Title.Section color="textGreen">
                <T>Přihlášení proběhlo úspěšně</T>
              </Title.Section>
              <Gap gap="20px" />
              <Text upperCase color="textGreen" letterSpacing="1.8px">
                <T>
                  Na uvedený e-mail vám brzy přijdou přesnější pokyny. děkujeme
                  vám za váš zájem a těšíme se na setkání!
                </T>
              </Text>
              <Gap gap="110px" mobileGap="60px" />

              {!headless && (
                <Link.OrangeLink pageKey={Link.HOMEPAGE}>
                  » <T>přejít na domovskou stránku</T>
                </Link.OrangeLink>
              )}
              <Gap gap="110px" />
            </Col>
          </PageContent>
        </Page>
        <Gap gap="60px" />
      </>
    )
  }
}

export default injectIntl(withExtraction(Thanks))

export const pageQuery = graphql`
  query {
    ks {
      allCollaborators(where: { isPublished: true }) {
        ...CollaboratorFragment
      }
    }
  }
`
