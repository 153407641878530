const React = require('react')
// const IntlProvider = require('./src/lib/intl/IntlProvider').default
const Layout = require('./src/layouts/DefaultLayout').default

// e.g. redux stores goes there
// exports.wrapRootElement = ({ element }) => {
//   return <div>{element}</div>
// }

exports.wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>
}
