import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import moment from 'moment'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Text from './Text'
import Row from './Row'
import Col from './Col'
import Gap from './Gap'
import Link from './Link'
import Title from './Title'
import Button from './Button'
import { Page, PageContent } from './Page'
import T from '../lib/intl/T'
import { up, addPagePadding } from '../lib/styles'
import pagesConfig from '../../pagesConfig'
import injectIntl from '../lib/intl/injectIntl'

const coursesLink = pagesConfig[Link.COURSES].to.cs

const variants = {
  instruments: {
    title: 'Chcete koupit instrumenty?',
    body: 'Instrumenty můžete nakoupit jedině na stránkách Centra Rozum.',
    btnText: 'Koupit instumenty',
    icon: 'trolley',
    link: {
      target: '_blank',
      asA: true,
      href: 'https://centrumrozum.cz/vzdelavaci-materialy/objednavka-miu/',
    },
  },
  tasting: {
    title: 'Chcete se blíže seznámit s metodami?',
    body:
      'Pravidelně pro vás organizujeme „Ochutnávky." Jedná se o jednodenní zážitkové semináře, které účastníky nechají nahlédnout do programu Reuvena Feuersteina Instrumentální obohacení a dynamického hodnocení LPAD.',
    btnText: 'chci na ochutnávku',
    icon: 'signUp',
    link: {
      anchor: Link.anchors.COURSES_BOX,
      to: `${coursesLink}?active=TASTING`,
    },
  },
  contact: {
    title: 'Chcete se nás na něco zeptat?',
    body:
      'Máte na nás otázku? Neváhejte a kontaktujte nás a my se vám co nejdřív ozveme.',
    btnText: 'napište nám',
    icon: 'email',
    link: {
      asA: true,
      href: 'mailto:info@a-t-c.cz', // todo maily někam do konfigu? Případně najít a nahradit všude
    },
  },
  supervision: {
    title: 'Potřebujete supervizi?',
    body:
      'Neváhejte a kontaktujte nás a my se s vámi domluvíme na podrobnostech.',
    btnText: 'napište nám',
    icon: 'email',
    link: {
      asA: true,
      href: 'mailto:info@a-t-c.cz',
    },
  },
  missing: {
    title: 'Chybíte tu? Napište nám.',
    body: 'Jste lektor nebo škola a chtěli byste být v naší databázi?',
    btnText: 'napište nám',
    icon: 'email',
    link: {
      asA: true,
      href: 'mailto:adela.krylova@a-t-c.cz',
    },
  },
  projects: {
    title: 'Chcete s námi spolupracovat? Napište nám.',
    body: 'Máte chuť s námi pracovat na nějakém projektu?',
    btnText: 'napište nám',
    icon: 'email',
    link: {
      asA: true,
      href: 'mailto:info@a-t-c.cz',
    },
  },
}

// parent container for relative position
const RelativeContainer = styled.div`
  ${({ theme: { sizes, colors }, bgColor }) => css`
    position: relative;
    background: ${colors[bgColor] || bgColor};
  `}
`

// wrapper for padding and positioning
const PaddingWrapper = styled.div`
  ${({ theme: { sizes, colors } }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;

    padding: 0 24px;
    ${up('menu')} {
      padding: 0 20px;
    }
  `}
`

// this is shadow wrapper with background
const Wrapper = styled.div`
  ${({ theme: { sizes, colors } }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    box-shadow: 0 0 43px rgba(0, 0, 0, 0.27);
    align-self: center;

    max-width: 600px;
    padding: 40px 32px 32px;
    ${up('menu')} {
      max-width: ${sizes.pageWidth}px;
      padding: 96px 24px 86px;
    }
  `}
`

class FooterBox extends React.Component {
  state = {
    height: 100,
  }

  wrapperRef = React.createRef()

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    const height = this.wrapperRef.current.clientHeight
    this.setState({ height })
    this.props.onHeightChange(height)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    const height = this.wrapperRef.current.clientHeight
    this.setState({ height })
    this.props.onHeightChange(height)
  }

  render() {
    const { height } = this.state
    let {
      variant: variantKey = 'instruments',
      intl: { language },
      bgColor,
    } = this.props

    if (variantKey[language]) {
      variantKey = variantKey[language]
    }

    const variant = variants[variantKey] || variants.instruments

    return (
      <RelativeContainer id="footer-box" bgColor={bgColor}>
        <PaddingWrapper ref={this.wrapperRef}>
          <Wrapper>
            <Col alignItems="center" width="100%">
              <Title.Section mobileTextAlign="center">
                <T>{variant.title}</T>
              </Title.Section>
              <Gap gap="36px" mobileGap="24px" />
              <Text textAlign="center" maxWidth="700px">
                <T>{variant.body}</T>
              </Text>
              <Gap gap="88px" mobileGap="20px" />
              <Button.PrimaryOrange iconLeft={variant.icon} link={variant.link}>
                <T>{variant.btnText}</T>
              </Button.PrimaryOrange>
            </Col>
          </Wrapper>
        </PaddingWrapper>

        <Gap gap={`${height / 2}px`} />
      </RelativeContainer>
    )
  }
}

FooterBox.defaultProps = {
  onHeightChange: () => {},
}

export default injectIntl(FooterBox)
