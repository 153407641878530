/**
 * our component, we can publish it
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'

let sMapLoaded = false // TODO: tohle udelat dotazem na tag v body ktery tam pridame po nahrani?
export class SMapPlaces extends React.Component {
  state = {
    suggestions: [],
    loading: false,
  }

  componentDidMount() {
    // append SMap script load
    if (!sMapLoaded) {
      const script = document.createElement('script')
      script.src = 'https://api.mapy.cz/loader.js'
      script.async = true
      script.defer = true
      script.onload = () => {
        window.Loader.async = true
        window.Loader.load(null, { suggest: true }, this.scriptLoaded)
      }
      document.body.appendChild(script)
    } else {
      console.log('script already appended ')
      this.scriptLoaded()
    }
  }

  scriptLoaded = () => {
    sMapLoaded = true
    if (!this.inputRef) {
      console.log('input suggest error - no ref')
      return
    }

    const { SMap } = window
    this.suggest = new SMap.Suggest(this.inputRef, {
      provider: new window.SMap.SuggestProvider({
        updateParams: this.props.updateParams || this.defaultUpdateParams,
      }),
    })
    this.suggest.addListener('request-items', (suggestions) => {
      console.log(suggestions)
      this.setState({ suggestions, loading: false })
    })
    console.log('suggest ready')
  }

  defaultUpdateParams = (params) => {
    /*
      tato fce se vola pred kazdym zavolanim naseptavace,
      params je objekt, staci prepsat/pridat klic a ten se prida
      do url
    */
    // const c = m.getCenter().toWGS84()
    // params.lon = c[0].toFixed(5)
    // params.lat = c[1].toFixed(5)
    // params.zoom = m.getZoom()
    // omezeni pro celou CR
    // params.bounds = '48.5370786,12.0921668|51.0746358,18.8927040'
    // povolime kategorie
    params.enableCategories = 1

    params.category = 'address_cz,street_cz,municipality_cz'

    // priorita jazyku, oddelene carkou
    params.lang = 'cs,en'
  }

  componentDidUpdate(prevProps) {
    // we need to invoke input change manually
    if (prevProps.value !== this.props.value) {
      clearTimeout(this.debounceTimeout)
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ loading: true })
      this.debounceTimeout = setTimeout(this.triggerSearch, this.props.debounce)
    }
  }

  triggerSearch = () => {
    if (!this.suggest) {
      console.log('suggest is undefined!')
      this.debounceTimeout = setTimeout(this.triggerSearch, this.props.debounce)
      return
    }
    this.suggest.send()
  }

  componentWillUnmount() {
    clearTimeout(this.debounceTimeout)
  }

  render() {
    const { value, children } = this.props
    const { suggestions, loading } = this.state

    return (
      <>
        <div style={{ display: 'none' }}>
          <input
            type="text"
            ref={(ref) => {
              this.inputRef = ref
            }}
            readOnly
            value={value}
          />
        </div>
        {children({ suggestions, loading })}
      </>
    )
  }
}

export default SMapPlaces

SMapPlaces.defaultProps = {
  debounce: 300,
  updateParams: null,
  value: '',
}

SMapPlaces.propTypes = {
  children: PropTypes.func.isRequired,
  debounce: PropTypes.number,
  updateParams: PropTypes.func,
  value: PropTypes.string,
}
