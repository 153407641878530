import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { addResponsivity, up, down, between, addFont } from '../lib/styles'
import { Col, Row, Text, Title, Gap, Link } from '.'

const Wrapper = styled.div`
  ${({ theme: { colors } }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;

    ${up('mobile')} {
      /* some reponsivity */
    }
  `}
`
const Number = styled.div`
  ${({ theme: { colors } }) => css`
    ${addFont('bree')}
    font-size: 102px;
    color: ${colors.brown};
  `}
`

class NumberClaim extends Component {
  render() {
    const { children, value } = this.props
    return (
      <Wrapper>
        <Number>{value}</Number>
        <Gap />
        <Text fontSize="26px" lineHeight="32px">{children}</Text>
      </Wrapper>
    )
  }
}

NumberClaim.propTypes = {}

export default NumberClaim
