import React, { Component, useEffect } from 'react'
import * as Yup from 'yup'
import { nanoid } from 'nanoid'
import axios from 'axios'
import { Formik, Form, useField, useFormikContext, Field, ErrorMessage } from 'formik'
// import { darken, lighten } from 'polished'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { withStyles } from '@material-ui/core/styles'
import PlacesAutocomplete from 'react-places-autocomplete'
import { Persist } from 'formik-persist'
import { addResponsivity, up, down, between, addFont, colors } from '../lib/styles'
import { Col, Row, Text, Title, Gap, Link, VH, Button, RH } from '.'
import {
  Checkbox,
  RadioGroup,
  Slider,
  Input,
  SLocationInput,
  FormikObserver,
  LocationInput,
} from './fields'
// import config from '../config'

const PopupOverlay = styled.div`
  ${({ theme: { colors }, show }) => css`
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
  `}
`
const Popup = styled.div`
  ${({ theme: { colors }, show }) => css`
    position: absolute;
    background: white;
    border-radius: 12px;

    max-width: 100%;
    max-height: 100%;
    top: 0;
    margin-top: 24px;
    left: 50%;
    padding: 24px 40px;
    transform: translateX(-50%);
  `}
`

const ResultsWrapper = styled.div`
  ${({ theme: { colors }, show }) => css`
    display: flex;
    ${!show
    && css`
      display: none;
    `}
    flex-direction: column;
    align-items: flex-start;

    ${up('mobile')} {
      /* some reponsivity */
    }
  `}
`

const Result = styled.div`
  ${({ theme: { colors }, emphasise, invalid }) => css`
    display: flex;
    background: white;
    border-radius: 8px;
    margin-bottom: 24px;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    transition: 0.7s;

    ${up(500)} {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
    ${emphasise
    && css`
      box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.25);
    `};

    opacity: 1;
    ${invalid
    && css`
      opacity: 0.4;
    `};
  `}
`

const ResultPlaceholder = styled.div`
  ${({ theme: { colors }, opacity }) => css`
    display: flex;

    opacity: ${opacity};
    background: white;
    border-radius: 8px;
    margin-bottom: 24px;
    padding: 24px;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    ${up(500)} {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  `}
`

const FormWrapper = styled.div`
  ${({ theme: { colors } }) => css`
    border-radius: 12px;
    background: white;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25);
    width: 390px;
    padding: 50px 44px;
  `}
`

const options = [
  {
    label: 'Tvrdé dříví',
    value: 'hard',
  },
  {
    label: 'Měkké dříví',
    value: 'soft',
  },
]

const CALC_ROW_BP = 850

class Calculator extends Component {
  state = {
    values: {},
    showFinalPopup: false,
    woodMakerId: null,
    invalidResults: true,
    resultIds: [],
    wasSubmitted: false,

    // results: [
    //   {
    //     totalPrice: 8754,
    //   },
    //   {
    //     totalPrice: 10378,
    //   },
    //   {
    //     totalPrice: 18504,
    //   },
    // ].map(r => ({ ...r, key: nanoid() })),
  }

  sendToServer = ({ method, path, data }) => axios({
    method,
    // url: `${config.apiUrl}${path}`,
    data,
  })

  handleSubmit = async (values, { setSubmitting }) => {
    console.log('values', values)

    try {
      const res = await this.sendToServer({
        method: 'post',
        path: 'calc/calc',
        data: {
          ...values,
        },
      })

      // console.log('res', res.data.data)
      const { offers, resultId } = res.data.data

      console.log('data', res.data.data)

      this.setState({
        values,
        invalidResults: false,
        results: offers,
        resultIds: [...this.state.resultIds, resultId],
        wasSubmitted: true,
      })
      setSubmitting(false)
    } catch (error) {
      console.log('error calc', error)
    }
  }

  handleSubmitOrder = (values, { setSubmitting }) => {
    console.log('values', values)
    setTimeout(() => {
      // alert(JSON.stringify(values, null, 2))
      // / TODO: odeslat

      this.setState({
        values,
        results: [
          {
            totalPrice: 8754,
          },
          {
            totalPrice: 10378,
          },
          {
            totalPrice: 18504,
          },
        ].map((r) => ({ ...r, key: nanoid() })),
      })
      setSubmitting(false)
    }, 400)
  }

  closeOrderPopup = (callback) => {
    callback()
    setTimeout(() => {
      this.setState({ showFinalPopup: false })
    }, 400)
  }

  render() {
    const { results, values: calcValues, showFinalPopup, invalidResults, wasSubmitted } = this.state
    return (
      <Row
        alignItems="flex-start"
        justifyContent="center"
        responsive
        bp={CALC_ROW_BP}
        mobileAlignItems="center"
      >
        {showFinalPopup && (
          <Formik
            initialValues={{
              name: '',
              email: '',
              phone: '',
              acceptedTerms: false,
            }}
            validationSchema={Yup.object({
              name: Yup.string()
                .max(50, 'Maximálně 50 znaků')
                .min(5, 'Alespoň 5 znaků')
                .required('Povinné'),
              email: Yup.string().email('Není emailová adresa').required('Povinné'),
              phone: Yup.string().required('Povinné'),
              acceptedTerms: Yup.boolean().required('Povinné'),
            })}
            onSubmit={this.handleSubmitOrder}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              errors,
              submitForm,
              setFieldValue,
              ...rest
            }) => (
              <PopupOverlay
                onClick={() => {
                  this.closeOrderPopup(setFieldValue)
                }}
              >
                <Popup
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                >
                  <Form>
                    <Persist name="order-form" />
                    <Col alignItems="flex-start">
                      <Col>
                        <Title.SectionTitle>Shrnutí objednávky</Title.SectionTitle>
                        <Gap gap="24px" />
                        <Text fontSize="15px" fontWeight={700}>
                          Přivezeme pro Vás:
                        </Text>
                        <Text fontSize="18px">
                          {calcValues.amount}
                          {' '}
                          PRMS
                          {' '}
                          {calcValues.woodCategory === 'hard' ? 'tvrdého' : 'měkkého'}
                          {' '}
                          dřeva
                          {' '}
                          {calcValues.isDry && '(pouze suché)'}
                        </Text>
                        <Gap gap="16px" />
                        <Text fontSize="15px" fontWeight={700}>
                          Na adresu:
                        </Text>
                        <Text fontSize="18px">
                          {calcValues.address && calcValues.address.address}
                        </Text>

                        <Gap gap="40px" />

                        <Title.SectionSubTitle textAlign="left">
                          Kontaktní údaje
                        </Title.SectionSubTitle>
                        <Text fontSize="14px">
                          (dodavatel vás bude kontaktovat s přesným termínem dodání)
                        </Text>
                        <Gap gap="8px" />
                      </Col>
                      <Col>
                        <Field name="name" component={Input} type="text" label="Vaše jméno" />
                        <Field
                          name="email"
                          component={Input}
                          type="email"
                          label="E-mail"
                          hint="soem shits"
                        />
                        <Field
                          name="phone"
                          component={Input}
                          type="tel"
                          label="Telefon"
                          hint="soem shits"
                        />
                        <Gap gap="16px" />
                        <Field
                          name="acceptedTerms"
                          component={Checkbox}
                          type="checkbox"
                          label="Souhlasím s podmínkami služby"
                        />
                        <Gap gap="48px" />
                        {/* <Button onClick={submitForm}>Závazně objednat</Button> */}
                        <Gap gap="48px" />
                      </Col>
                    </Col>
                  </Form>
                </Popup>
              </PopupOverlay>
            )}
          </Formik>
        )}

        <FormWrapper>
          <Formik
            initialValues={{
              woodCategory: 'hard',
              address: { address: '' },
              isDry: false,
              amount: 5,
            }}
            validate={(values) => {
              // hijack validate method to know, that sth is changed
              console.log('invalidate!')
              this.setState({ invalidResults: true })

              const errors = {}
              // custom validate address
              if (!values.address || !values.address.address) {
                errors.address = 'Povinné'
              } else if (values.address.source !== 'addr') {
                // not full address
                errors.address = 'Zadejte i číslo domu'
              }

              return errors
            }}
            validationSchema={Yup.object({
              woodCategory: Yup.string()
                .oneOf(
                  // [config.woodCategorySoft, config.woodCategoryHard],
                  'Vyberte typ dřeva prosím.',
                )
                .required('Povinné'),
            })}
            onSubmit={this.handleSubmit}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              errors,
              submitForm,
              isSubmitting,
            }) => (
              <Form>
                <Persist name="calc-form" />

                <Field
                  name="woodCategory"
                  component={RadioGroup}
                  options={options}
                  label="Typ dřeva (poleno 33 cm)"
                  hint="nejaky pekny hint"
                />
                <Gap gap="48px" />
                <Field
                  name="isDry"
                  type="checkbox"
                  component={Checkbox}
                  label="Pouze suché dřevo"
                  hint="asdjf ajfj a;sjdfka s;dkf"
                />
                <Gap gap="48px" />
                <Field
                  name="amount"
                  type="checkbox"
                  component={Slider}
                  label="Množství"
                  max={30}
                  min={1}
                  hint={(
                    <div>
                      neco
                      {' '}
                      <br />
                      {' '}
                      neco 2
                    </div>
                  )}
                />
                <Gap gap="48px" />

                <Field
                  name="address"
                  component={SLocationInput}
                  type="text"
                  label="Vaše adresa"
                  hint="soem shits"
                />
                <Gap gap="48px" />

                {/* <Button width="100%" onClick={submitForm} disabled={isSubmitting}>
                  srovnej to!️
                </Button> */}
                {/* {JSON.stringify(errors)} */}
              </Form>
            )}
          </Formik>
        </FormWrapper>
        <Gap.Fluid min="56px" max="80px" />
        <ResultsWrapper show>
          <Col>
            <Gap gap="32px" />
            <Title.SectionTitle>Nejlevnější nabídky</Title.SectionTitle>
            <Gap gap="24px" />

            {results
              && results.length > 0
              && results.map((result, index) => (
                <Result key={result.key} emphasise={index === 0} invalid={invalidResults}>
                  <Text
                    fontWeight="700"
                    // fontSize={index === 0 ? '20px' : '18px'}
                  >
                    Dodavatel&nbsp;
                    {index + 1}
                  </Text>
                  <Gap.Fluid gap="60px" max="60px" min="16px" />
                  <Col>
                    <Text fontWeight="700" fontSize={index === 0 ? '20px' : '18px'}>
                      {result.totalPrice}
                      {' '}
                      Kč
                    </Text>

                    <Text>
                      {Math.round((result.totalPrice / calcValues.amount) * 10) / 10}
                      {' '}
                      Kč za PRMS
                    </Text>
                  </Col>
                  <Gap.Fluid gap="60px" max="60px" min="16px" />
                  <Col>
                    <Button.PrimaryGreen
                      outlined={index !== 0}
                      onClick={() => {
                        if (invalidResults) {
                          alert('Změnili jste údaje v kalulačce. Sorvnejte ceny znovu.')
                          return
                        }
                        if (index !== 0) {
                          alert(
                            'Jste si jisti, že chcete vybrat dražšího dodavatele? Pokud ano, tak nás prosím kontaktujte. Tato funkce zatím není implementována. Připadně zvolte nejlevnějšího dodavatele. Děkujeme.',
                          )
                          return
                        }

                        this.setState({ woodMakerId: 123, showFinalPopup: true })
                        // TODO: show popup
                      }}
                    >
                      Objednat
                    </Button.PrimaryGreen>
                  </Col>
                </Result>
              ))}

            {(!results || !results.length) && (
              <>
                {!wasSubmitted ? (
                  <>
                    <ResultPlaceholder opacity={0.8}>
                      <Text fontWeight="700">Dodavatel 1</Text>
                    </ResultPlaceholder>
                    <ResultPlaceholder opacity={0.6}>
                      <Text fontWeight="700">Dodavatel 2</Text>
                    </ResultPlaceholder>
                    <ResultPlaceholder opacity={0.45}>
                      <Text fontWeight="700">Dodavatel 3</Text>
                    </ResultPlaceholder>
                    <RH desktop bp={CALC_ROW_BP}>
                      <Text>← Začněte vyplněním kalkulačky vlevo ←</Text>
                    </RH>
                    <RH mobile bp={CALC_ROW_BP}>
                      <Text>↑ Začněte vyplněním kalkulačky výše ↑</Text>
                    </RH>
                  </>
                ) : (
                  <Text>Nenalezena žádná nabídka. Zkuste upravit údaje v kalkulačce 🔥</Text>
                )}
              </>
            )}
          </Col>
        </ResultsWrapper>
      </Row>
    )
  }
}

Calculator.propTypes = {}

export default Calculator
