// everything with styles, theme etc. goes here
import styled, { css } from 'styled-components'

// style guide
// https://drive.google.com/drive/u/0/folders/1-UEXcU2IzN7Q9-0bXluHtIecXbunvq9r

// all major colors
export const colors = {
  text: '#261616',
  textGreen: '#284c36',
  red: '#e24849',
  black: '#262626',
  orange: '#f9ad3d',
  darkOrange: '#ee934b',
  green: '#345c42',
  darkGreen: '#234731',
  lightGreen: '#5ca37b',
  grey: '#969696',
  lightGrey: '#e6e6e6',
  disabledBtn: '#9d9d9d',

  // bg colors
  bgPink: '#f7f4f4',
  bgGrey: '#f3f5f9',

  // specialities
  greenGradient: 'linear-gradient(320deg, #093824 0%, #3b6c50 100%)',

  // old colors below
  btnHover: '#f99908',
  lightOrange: '#FFB951',
}

// theme vars accessible in every component
export const theme = {
  colors,
  breakpoints: {
    // not used yet --> from bootstrap for info only
    // sm: '576px',
    // md: '768px',
    // lg: '992px',
    // xl: '1200px',

    everything: 0,
    mobile: 866, // main breakpoint for mobile
    menu: 866, // bp for hamburger
    pageWidth: 1280, // also bp for ...

    tablet: 980,
    desktop: 1200,
  },
  fonts: {
    noto: '"Noto Sans", sans-serif',
  },
  pageSidePadding: '24px',
  mobilePageSidePadding: '20px',
  sizes: {
    // number constants
    pageWidth: 1280,
    maxMenuWidth: 1920,
  },
}

// style helpers and snippets
// media queries helpers, if arg is string, then it's bp name, otherwise it's pixel value
export const down = value =>
  `@media (max-width: ${
    typeof value === 'string' ? theme.breakpoints[value] : value
  }px)`
export const between = (value, value2) => `@media (min-width: ${
  typeof value === 'string' ? theme.breakpoints[value] : value
}px) and
  (max-width: ${
  typeof value2 === 'string' ? theme.breakpoints[value2] : value2
}px)`

export const up = value =>
  `@media (min-width: ${
    typeof value === 'string' ? theme.breakpoints[value] : value
  }px)`

export const addFont = family =>
  `font-family: ${theme.fonts[family] || family};`

// adds snippets which ResponsivityHelper uses
// props can be bp names or pixels
// to use mobile first, use **After, which uses min-width
export const addResponsivity = ({ displayAs: displayAsArg } = {}) => css`
  ${({
    hideBelow,
    showBelow,
    hideAfter,
    showAfter,
    displayAs = displayAsArg || 'block',
    hideBetween,
    showBetween,
    mobile, // useful shorthand for hideAfter="mobile", breakpoint can be changed via bp prop
    desktop, // useful shorthand as 'mobile'
    bp = 'mobile', // or can be changed to this bp!!! B-)
    // show, // overrides everything
    // hide, // overrides everything
  }) => css`
    display: ${displayAs};

    /* ${show &&
      css`
        display: ${displayAs} !important;
      `}
    ${hide &&
      css`
        display: none !important;
      `} */

    ${hideBelow &&
      css`
        display: ${displayAs};
        ${down(hideBelow)} {
          display: none;
        }
      `}
    ${showBelow &&
      css`
        display: none;
        ${down(showBelow)} {
          display: ${displayAs};
        }
      `}
    ${showAfter &&
      css`
        display: none;
        ${up(showAfter)} {
          display: ${displayAs};
        }
      `}
    ${hideAfter &&
      css`
        display: ${displayAs};
        ${up(hideAfter)} {
          display: none;
        }
      `}
    ${showBetween &&
      css`
        display: none;
        ${between(...showBetween)} {
          display: ${displayAs};
        }
      `}
    ${hideBetween &&
      css`
        display: ${displayAs};
        ${between(...hideBetween)} {
          display: none;
        }
      `}
    ${mobile &&
      css`
        display: ${displayAs};
        ${up(bp)} {
          display: none;
        }
      `}
    ${desktop &&
      css`
        display: none;
        ${up(bp)} {
          display: ${displayAs};
        }
      `}
  `}
`

export const addPagePadding = () => css`
  ${({ theme: { pageSidePadding, mobilePageSidePadding, breakpoints } }) => css`
    padding-left: ${mobilePageSidePadding};
    padding-right: ${mobilePageSidePadding};
    ${up(breakpoints.mobile)} {
      padding-left: ${pageSidePadding};
      padding-right: ${pageSidePadding};
    }
  `}
`

export const addFullWidth = () => css`
  position: relative;
  width: calc(100vw - 16px);
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  ${up(1440)} {
    max-width: 1440px;
  }
`
