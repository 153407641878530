import React from 'react'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'
import styled, { css } from 'styled-components'
import { Link, RH, Row, VH, Col, withScrollDetection } from '.'
import { fadeInFromNone, menuFadeIn, menuFadeOut } from '../lib/animations'
import { down, up, addResponsivity, between, theme } from '../lib/styles'
import { links } from '../links'
import Gap from './Gap'
import T from '../lib/intl/T'

// assets
import logoCsPath from '../data/images/logo.svg'
import logoSkPath from '../data/images/logo-myslenie.png'
import menuOpenPath from '../data/images/menu-open.svg'
import menuClosePath from '../data/images/menu-close.svg'
import injectIntl from '../lib/intl/injectIntl'
import { isCs, isSk } from '../lib/appVersions'

// constants
const MENU_HEIGHT_MOBILE = '80px'

const NavLink = styled(Link).attrs(props => ({
  withUnderline: false,
  noStyle: true,
}))`
  ${({
    theme: { colors },
    isActive,
    linkText,
    isHighlightedOrange,
    isHighlightedGreen,
  }) => css`

    position: relative;
    transition: 0.2s;

    & > a {
      text-transform: uppercase;
      font-size: 15px;
      letter-spacing: 1.8px;
      font-weight: 400;
      transition: 0.3s;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;

      height: 56px;
      text-align: left;
      padding: 0 32px;
      color: ${isActive ? colors.orange : 'white'};
      ${up('menu')} {
        height: 90px;
        padding: 0 8px;
        text-align: center;
        color: ${colors.green};
      }

      ${up(1130)} {
        padding: 0 14px;
      }
      ${up(1350)} {
        padding: 0 27px;
      }

      &:hover {
        font-weight: 600;
      }
      /* hack for bold hover jump */
      &::before {
        display: block;
        content: "${linkText}";
        font-weight: 600;
        height: 0;
        overflow: hidden;
        visibility: hidden;
        display: block;
      }
    }
    /* fancy upper line */
    &::after {
      display: block;
      content: "";
      width: 100%;
      height: 6px;
      position: absolute;
      top: 0;
      transition: 0.2s;
      background: ${isHighlightedOrange ? colors.green : colors.orange};
      opacity: 0;
      ${up('menu')} {
        opacity: ${isActive ? 1 : 0};
      }
    }


     /* highlighted items! */
     ${(isHighlightedGreen || isHighlightedOrange) &&
       css`
         ${up('menu')} {
           background: ${isHighlightedOrange && colors.orange};
           background: ${isHighlightedGreen && colors.green};
           &:hover {
             background: ${isHighlightedOrange && colors.darkOrange};
             background: ${isHighlightedGreen && colors.darkGreen};
           }

           & > a {
             color: white;
             /* font-weight: 700; */
             &:hover {
               color: white;
             }
           }
         }
       `}


  `}
`

const Wrapper = styled.div`
  ${({
    theme: {
      sizes: { maxMenuWidth },
    },
    absolute,
  }) => css`
    background: white;
    align-self: center;
    max-width: ${maxMenuWidth}px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 auto;
    position: fixed;
    z-index: 1000;

    height: ${MENU_HEIGHT_MOBILE};
    padding: 0 16px;
    ${up('menu')} {
      height: auto;
      padding: 0 0 0 10px;
    }
  `}
`

const Container = styled.div`
  ${({ theme: { colors, sizes }, isPinned }) => css`
    max-width: ${sizes.maxMenuWidth}px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    display: flex;
    height: auto;
    ${up('menu')} {
    }
  `}
`

const Logo = styled.img`
  ${({ isPinned, smallerBp }) => css`
    display: block;
    width: 175px;
    /* height: 47px; */
    ${up('menu')} {
      width: 205px;
      width: ${isCs ? '205px' : '150px'};
      /* height: 69px; */
    }
    ${up(1350)} {
      margin-left: 24px;
    }
  `}
`

const NavList = styled.div`
  ${({ theme: { sizes, colors }, isOpen }) => css`
    flex-direction: row;
    display: none;

    ${isOpen &&
      css`
        background: white;
        display: flex;
        position: absolute;
        flex-direction: column;
        background: ${colors.darkGreen};
        background: ${colors.greenGradient};
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        overflow-y: auto;
      `}

    ${up('menu')} {
      background: none;
      display: flex;
      position: relative;
    }
  `}
`

const Burger = styled.div`
  ${({ theme: { sizes }, isOpen }) => css`
    cursor: pointer;
    z-index: 1;
    /* margin: 16px 0 0; */
    position: relative;
    /* height: 32px; */
    /* height: 48px;
    width: 48px; */

    padding: 8px;

    ${up('menu')} {
      display: none;
    }
  `}
`

const BurgerIcon = styled.img`
  ${({ theme: { sizes }, isOpen, animationDelay = 0.1 }) => css`
    display: block;
    /* padding: 35px 0 20px; */
    /* top: 50%;
    left: 50%;
    position: relative;
    transform: translate(-50%, -50%); */
  `}
`

const menuLinks = [
  {
    text: 'Aktuality',
    pageKey: links.BLOG,
  },
  {
    text: 'Lektoři a školy',
    pageKey: links.COLLABORATORS,
  },
  {
    text: 'O metodách',
    pageKey: links.METHODS,
    // disableEn: true,
  },
  {
    text: 'O projektech',
    pageKey: links.PROJECTS,
  },
  {
    text: 'O nás',
    pageKey: links.ABOUT,
  },
  {
    text: 'Kurzy a akce',
    pageKey: links.COURSES,
    isHighlighted: true,
    disableEn: true,
  },
]

class Menu extends React.Component {
  state = {
    isOpen: false,
    height: 80,
  }

  targetRef = React.createRef()

  targetElement = null

  componentDidMount() {
    // window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleResize)
    const height = this.wrapperRef.clientHeight
    this.setState({ height })
    this.targetElement = this.targetRef.current
  }

  componentWillUnmount() {
    // window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleResize)
    clearAllBodyScrollLocks()
  }

  handleResize = () => {
    if (window.innerWidth > theme.breakpoints.menu) {
      this.closeMenu()
    }
  }

  toggleMenu = () => {
    const { isOpen } = this.state
    if (isOpen) {
      enableBodyScroll(this.targetElement)
    } else {
      disableBodyScroll(this.targetElement)
    }

    this.setState({ isOpen: !isOpen })
  }

  closeMenu = () => {
    this.setState({ isOpen: false })
    enableBodyScroll(this.targetElement)
  }

  closeLangMenu = () => {
    this.setState({ isLangOpen: false })
  }

  isLinkActive = linkPageKey => {
    const { pageKey } = this.props.pageContext
    return linkPageKey === pageKey
  }

  render() {
    const { isOpen, isPinned, height } = this.state
    const { pageKey, slugs, language } = this.props.pageContext
    const { t } = this.props.intl

    const mainLang = isSk ? 'sk' : 'cs'

    const logoPath = isCs ? logoCsPath : logoSkPath

    return (
      <>
        <Wrapper
          isPinned={isPinned}
          isOpen={isOpen}
          ref={ref => {
            this.wrapperRef = ref
          }}
          height={height}
        >
          <Container>
            <div>
              <Link pageKey="/" onClick={this.closeMenu}>
                <Logo src={logoPath} />
              </Link>
            </div>

            <NavList isOpen={isOpen} ref={this.targetRef}>
              <RH mobile>
                <Gap gap="90px" />
              </RH>
              {menuLinks.map((l, index) =>
                l.disableEn && language === 'en' ? null : (
                  <NavLink
                    isOpen={isOpen}
                    key={l.pageKey}
                    pageKey={l.pageKey}
                    isActive={this.isLinkActive(l.pageKey)}
                    onClick={this.closeMenu}
                    animationDelay={0.04 * index}
                    isPinned={<T>{l.text}</T>}
                    linkText={t(l.text, language)}
                    isHighlightedOrange={l.isHighlighted}
                  >
                    <T>{l.text}</T>
                  </NavLink>
                )
              )}

              <NavLink
                pageKey={pageKey}
                preferLang={language === 'en' ? mainLang : 'en'}
                slugs={slugs}
                isHighlightedGreen
                fallbackPage="/"
              >
                {language === 'en' ? mainLang : 'en'}
              </NavLink>
            </NavList>

            <Row justifyContent="flex-end" grow="10" hideAfter="menu">
              <Burger isOpen={isOpen} onClick={this.toggleMenu}>
                {isOpen ? (
                  <BurgerIcon src={menuClosePath} />
                ) : (
                  <BurgerIcon src={menuOpenPath} />
                )}
              </Burger>
            </Row>
          </Container>
        </Wrapper>
        <Gap gap={`${height}px`} />
      </>
    )
  }
}

export default injectIntl(Menu)
