import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import injectIntl from '../lib/intl/injectIntl'
import pagesConfig from '../../pagesConfig'

const SEO = ({
  title,
  titleTemplate,
  description,
  image,
  pathname,
  isArticle,
  intl,
}) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitleTemplate,
          defaultDescription,
          siteUrl,
          defaultImage,
        },
      },
    }) => {
      const pConfig = pagesConfig[intl.pageKey]

      const lang = intl.language || intl.defaultLanguage

      const configMetaDesc =
        (typeof pConfig?.metaDesc === 'object' && pConfig.metaDesc[lang]) ||
        pConfig.metaDesc

      const seo = {
        title,
        description: description || configMetaDesc || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname || '/'}`,
        titleTemplate: titleTemplate || defaultTitleTemplate,
      }

      return (
        <>
          <Helmet title={seo.title} titleTemplate={seo.titleTemplate}>
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />

            {/* FB open graph */}
            {seo.url && <meta property="og:url" content={seo.url} />}
            {isArticle
              ? <meta property="og:type" content="article" />
              : <meta property="og:type" content="website" />}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description &&
              <meta property="og:description" content={seo.description} />}
            {seo.image && <meta property="og:image" content={seo.image} />}

            {/* Twitter */}
            <meta name="twitter:card" content="summary_large_image" />
            {/* {twitterUsername && (
                <meta name="twitter:creator" content={twitterUsername} />
              )} */}
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description &&
              <meta name="twitter:description" content={seo.description} />}
            {seo.image && <meta name="twitter:image" content={seo.image} />}

            {/* make it nice */}
            <meta name="theme-color" content="#31553c" />
          </Helmet>
        </>
      )
    }}
  />
)

export default injectIntl(SEO)

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  isArticle: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  isArticle: false,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitleTemplate: titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
      }
    }
  }
`
