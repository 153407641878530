import React from 'react'
import styled, { css } from 'styled-components'
import { up, addFont, addResponsivity } from '../lib/styles'

const TextComponent = styled.div`
  ${({
    theme: { colors },
    fontSize = '16px',
    fontWeight = 400,
    lineHeight = '24px',
    padding,
    margin,
    upperCase,
    letterSpacing,
    mobileLetterSpacing,
    maxWidth,
    mobileFontSize,
    mobileLineHeight,
    bp = 'mobile',
    noWrap,
    color = 'text',
    textAlign,
    mobileTextAlign,
    whiteSpace,
  }) => css`
    ${addFont('noto')}
    ${addResponsivity()}

    font-weight: ${fontWeight};
    color: ${colors[color] || color};
    white-space: ${noWrap && 'nowrap'};
    padding: ${padding};
    white-space: ${whiteSpace};
    margin: ${margin};
    text-transform: ${upperCase && 'uppercase'};
    letter-spacing: ${mobileLetterSpacing || letterSpacing};
    max-width: ${maxWidth};

    font-size: ${mobileFontSize || fontSize};
    line-height: ${mobileLineHeight || lineHeight};
    text-align: ${mobileTextAlign || textAlign};

    ${up(bp)} {
      font-size: ${fontSize};
      line-height: ${lineHeight};
      letter-spacing: ${letterSpacing};
      text-align: ${textAlign};
    }

    strong {
      font-weight: 700;
    }
    /* because of links in perex. If it cause issues then move it directly to person and post */
    a {
      text-decoration: underline;
    }
  `}
`

// define basic styles used on page

// toto je vychozi text na 60 % stranky
// nekdy je na mobilu responzivni --> zvetsi se
const Text = props => (
  <TextComponent
    fontSize="15px"
    lineHeight="28px"
    mobileFontSize={props.responsive ? '13px' : undefined}
    mobileLineHeight={props.responsive ? '18px' : undefined}
    {...props}
  />
)

Text.Wide = props => <Text letterSpacing="0.75px" {...props} />

// nejmensi normalni text ma 15px
Text.Small = props => (
  <TextComponent
    fontSize="13px"
    lineHeight="20px"
    // mobileFontSize={props.responsive ? '18px' : undefined}
    // mobileLineHeight={props.responsive ? '30px' : undefined}
    {...props}
  />
)

Text.Footer = props => (
  <TextComponent
    fontSize="15px"
    lineHeight="25px"
    mobileFontSize="12px"
    mobileLineHeight="18px"
    letterSpacing="0.6px"
    color="white"
    fontWeight="300"
    {...props}
  />
)

Text.Strong = styled.span`
  font-weight: 700;
`

Text.Error = styled(Text)`
  ${({ theme: { colors } }) => css`
    color: ${colors.red};
    font-weight: 700;
  `}
`

export default Text
