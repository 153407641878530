/**
 * (post) content formatting
 */

import React from 'react'
import HtmlToReact, { Parser as HtmlToReactParser } from 'html-to-react'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'

// components
import { Gap, Row, Col, Link } from '../components'
import { anchors, links } from '../links'

const parseStyles = styles =>
  styles
    .split(';')
    .filter(style => style.split(':')[0] && style.split(':')[1])
    .map(style => [
      style
        .split(':')[0]
        .trim()
        .replace(/-./g, c => c.substr(1).toUpperCase()),
      style.split(':')[1].trim(),
    ])
    .reduce(
      (styleObj, style) => ({
        ...styleObj,
        [style[0]]: style[1],
      }),
      {}
    )

export const replaceShortCodes = content => {
  let searchIndex = 0
  while (content.indexOf('[video ', searchIndex) !== -1) {
    const startIndex = content.indexOf('[video ', searchIndex)
    const endIndex = content.indexOf(']', startIndex) + 1
    const videoCodeIndex = content.indexOf('code="', startIndex) + 6
    const videoCode = content.substring(videoCodeIndex, videoCodeIndex + 11)
    content = `${content.substring(0, startIndex)}
    <youtubewidget data-video-id="${videoCode}"></youtubewidget>
    ${content.substring(endIndex)}
    `
    searchIndex = endIndex
  }
  searchIndex = 0
  while (content.indexOf('[link ', searchIndex) !== -1) {
    const startIndex = content.indexOf('[link ', searchIndex)
    const endIndex = content.indexOf(']', startIndex) + 1
    const pageKey = parseAttribute(content, startIndex, endIndex, 'pageKey') || ''
    const anchor = parseAttribute(content, startIndex, endIndex, 'anchor') || ''
    const text = parseAttribute(content, startIndex, endIndex, 'text') || ''
    const active = parseAttribute(
      content,
      startIndex,
      endIndex,
      'activeCourseType'
    )
    const external = parseAttribute(content, startIndex, endIndex, 'external') || ''
    const href = parseAttribute(content, startIndex, endIndex, 'href') || ''

    // console.log('external', external)
    // console.log('href', href)
    // console.log('active', active)
    // console.log('text', text)
    // console.log('anchor', anchor)
    // console.log('pageKey', pageKey)

    content = `${content.substring(0, startIndex)}
      <linkwidget data-page-key="${pageKey}" data-external="${external}" data-href="${href}" data-anchor="${anchor}" data-params-active="${active ||
      ''}">${text}</linkwidget>
      ${content.substring(endIndex)}
      `
    searchIndex = endIndex
  }

  return content
}

function parseAttribute(content, startIndex, endIndex, key) {
  const text = content.substring(startIndex, endIndex)
  // console.log('text', text)
  let keyIndex = text.indexOf(key + '="')
  if (keyIndex === -1) {
    return undefined
  }

  const startTextIndex = keyIndex + key.length + 2
  const endTextIndex = text.indexOf('"', startTextIndex)
  // console.log(
  //   'continue parsing',
  //   key,
  //   keyIndex,
  //   text,
  //   text.substring(startTextIndex, endTextIndex)
  // )
  return text.substring(startTextIndex, endTextIndex)
}

// CONTENT parsing
const htmlToReactParser = new HtmlToReactParser()
const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React)
const getProcessingInstruction = (images, procedures = []) => [
  {
    // replace p tag with custom component
    shouldProcessNode(node) {
      return node.name === 'linkwidget'
    },
    processNode(node, children, index) {
      const pageKey = links[node.attribs['data-page-key']]
      const external = !!node.attribs['data-external']
      const href = node.attribs['data-href']
      const anchor = node.attribs['data-anchor']
        ? anchors[node.attribs['data-anchor']]
        : undefined
      let params

      if (node.attribs['data-params-active']) {
        params = { active: node.attribs['data-params-active'] }
      }

      // console.log('params', params)
      // console.log('anchor', anchor)
      // console.log('pageKey', pageKey)
      // console.log('node', node)


      return (
        <Link.TextLink
          key={index}
          pageKey={pageKey}
          anchor={anchor}
          params={params}
          external={external}
          href={href}
        >
          {children}
        </Link.TextLink>
      )
    },
  },
  {
    // replace p tag with custom component
    shouldProcessNode(node) {
      return node.name === 'p'
    },
    processNode(node, children, index) {
      return (
        <div
          className="p"
          key={index}
          style={
            node.attribs.style ? parseStyles(node.attribs.style) : undefined
          }
        >
          {children}
        </div>
      )
    },
  },
  {
    // Custom <img> processing
    shouldProcessNode(node) {
      return node.name === 'img'
    },
    processNode(node, children, index) {
      const foundImage = images.find(
        image =>
          node.attribs.src &&
          node.attribs.src.indexOf(image.relativePath) !== -1
      )
      if (foundImage && foundImage.contentImage) {
        const noMask = node.attribs.class && node.attribs.class === 'nomask'
        const ImageComponent = Img

        return (
          <Col maxWidth="587px" key={node.attribs.src + index}>
            <Gap gap="24px" mobileGap="16px" />
            <ImageComponent
              alt={node.attribs.alt}
              fluid={
                !noMask
                  ? foundImage.contentImage.fluid
                  : foundImage.contentImage.fluidNoCrop
              }
            />
            <Gap gap="24px" mobileGap="16px" />
          </Col>
        )
      }
      return <img {...node.attribs} />
    },
  },
  {
    // Anything else
    shouldProcessNode(node) {
      return true
    },
    processNode: processNodeDefinitions.processDefaultNode,
  },
]

export const enhanceContent = ({
  content = '',
  contentImages = [],
  procedures,
}) => {
  // replacing shortCodes with custom tags
  // const postContent = replaceShortCodes(content)

  // replace tags with react components
  const contentReactElement = htmlToReactParser.parseWithInstructions(
    content,
    () => true,
    getProcessingInstruction(contentImages, procedures)
  )

  return contentReactElement
}
