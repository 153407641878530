import styled, { css, keyframes } from 'styled-components'

export const fadeInFromNone = keyframes`
  0% {
      /* display: none; */
      opacity: 0;
  }

  1% {
      display: block;
      opacity: 0;
  }

  100% {
      display: block;
      opacity: 1;
  }
`

export const menuFadeIn = keyframes`

  0% {
      opacity: 0;
      transform: translateY(-100%);
      /* position: absolute; */

  }

  100% {
      /* display: fixed; */
      opacity: 1;
      transform: translateY(0);
      /* position: fixed; */

  }
`

export const menuFadeOut = keyframes`
  0% {
      /* display: fixed; */
      /* opacity: 1;
      transform: translateY(0); */
      /* position: fixed; */
      opacity: 1;
      transform: translateY(0);
  }

  100% {
      /* display: fixed; */
      opacity: 0;
      transform: translateY(-100%);
      /* position: fixed; */
  }
`

export const imageHover = keyframes`
  0% {
    filter: none;
  }

  100% {
    filter: grayscale(100%) brightness(40%) sepia(100%)
              hue-rotate(-68deg) saturate(600%) contrast(1);
  }
`

export const imageHoverOut = keyframes`
  0% {
    filter: grayscale(100%) brightness(40%) sepia(100%)
              hue-rotate(-68deg) saturate(600%) contrast(1);
  }

  100% {
    filter: none;
  }
`
