import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'
import {
  CarouselProvider,
  Slider,
  Slide,
  Dot,
  DotGroup,
} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import quotationSrc from '../data/images/quotation.png'
import { Row, Text, Title, Col, Gap } from '.'
import { up } from '../lib/styles'
import T from '../lib/intl/T'
import { isCs } from '../lib/appVersions'

const bgImageBp = 1044

const textsCs = [
  'Myslím si, že mě to celkově zklidnilo, našla jsem vnitřní klid sama v sobě. Pochopila jsem, že každá situace má více řešení, a učím se s touto myšlenkou pracovat. Co pro mě bylo velice složité přijmout, je, že můžu chybovat a nejsem o nic horší než ostatní – jsem člověkem.',
  'Získala jsem celkově nový obzor a úplně jiný pohled na svět a lidi. Začátek mi přišel neefektivní, ale později se děly a dějí velice pozitivní věci. Vše je pro mě přijatelnější a barevnější, nejsou to jen tečky! :-)',
  'Nejvíce mi v osobním i profesním životě pomohlo si v klidu uvědomit, že ke zvládnutí jakéhokoliv problému je mnoho cest a pomůcek, stačí si na ně jen vzpomenout a použít je – hlavně: klíče, strategie, priority a ignorování drobných, nepodstatných chyb. Teď umím mávnout rukou ve chvíli, kdy nejde o nic důležitého.',
  'Hodně osobní byl střet sama se sebou, na vlastní kůži jsem poznala, jak zbytečně dělám unáhlené závěry. Díky několika stránkám je to lepší. :-) Skvělý byl postup od nejjednoduššího zároveň se zamyšlením se nad jednotlivými postupy, cestami. Asi na str. 2 jsem se pěkně nudila, ale pak mě to doběhlo a moje pýcha si nabila nos. Nejtěžší bylo překonat vlastní vztek z nezvládnutého políčka na str. 12.',
  'I když jsem absolvovala několik kurzů zaměřených na zvýšení efektivity práce a všechny mě někam posunuly, tento je naprosto osobní a to je to, co funguje, aspoň u mě. Kurz se mi moc líbil, hlavně kvůli tvému citlivému, trpělivému a cílevědomému přístupu. Taky jsem byla díky tobě od začátku klidná, protože jsem věděla, že mi ten kurz pomůže, že ho zvládnu. Takže paráda! A téměř všichni mí známí i rodina mi říkají: „Ty ses nějak změnila, s tebou se teď dá mnohem líp vycházet.“',
  'Narostla mi křídla.',
  'Velmi obohacující, vztáhla jsem si spoustu strategií na sebe a do svého života, jsem za to velmi vděčná. Po tom druhém týdnu mi to dává větší a větší smysl.',
  'Naučila jsem se vytvořit tabulky a systém, strategii. Když se vyhecuji a chci, tak to zvládnu.',
  'Vyzkoušel jsem si na sobě, že jsem zbrklý. Uvědomil jsem si, že někdy je lepší si vše třikrát přečíst než něco dělat.',
  'Jednotlivé listy jsou pro mě projekčním plátnem, kam člověk vkládá své rozpoložení, emoce.',
  'Mnohovrstevnatost – prohlubuje se ve mně pochopení slova tolerance přes různost a odlišnost = toleranci k druhým a postupně i zmírnění negativního sebehodnocení.',
  'Metoda zviditelňuje nadhled – přemýšlení o přemýšlení (velký zlom, který může metoda přinést).',
  'Zvědomování vlastních témat – přijetí a respektování názorů druhého člověka – „Aha, ty to máš takhle, to je zajímavý“ – bolestné uvědomění si, že ne vždy vím vše nejlíp.',
  'Pracuji s tím, že jsem nesoustředěná, roztěkaná, dělám deset věcí najednou. Povedlo se mi soustředěně vyplnit stránku – naděje, že je to možné u mě i u druhých (klientů).',
  'Jak velký smysl má vrátit se k začátku, ptát se na úplně jednoduché věci, když se chci posunout dál.',
  'Jde o proces, ne o výsledek. Hodně se mi to navázalo na prožitek (důležitost prožitku v každodenním životě). Teprve když přijde prožitek, narazím sama na sebe, uvědomím si něco důležitého (emoce).',
  'Pro mě stoupla důležitost otázky – že obyčejná otázka dokáže člověka někam posunout a otevřít nejrůznější témata.',
  'Je to o hledání potenciálu. Neodsoudím člověka, nenechám ho s tím, že „to necháme, to ti nejde, přejdeme na něco jiného“, ale hledám způsoby, jak může k cíli dojít právě on.',
  'Respektování individuality a jiných postupů každého člověka, nehodnocení druhých, svoboda v tom, být nehodnocen. Pociťovala jsem velký rozdíl oproti neustálému hodnocení světa, posílilo to můj pocit vlastní kompetence a sebedůvěry.',
  'Uvědomění si, že úkoly mají různá řešení a vícero řešení může být správných, vícero cestami můžu dojít do stejného cíle, nejsem vázaná jedním způsobem řešení, jednou cestou.',
  'Úcta k člověku – každý člověk má v sobě drahokamy, každý člověk se k těm svým drahokamům může dostat, zprostředkovatel mu v tom pomáhá.',
  'Začala jsem dělat instrumentální obohacování, protože moje dítě bylo „divný“. Měla jsem představu, že moje dítě to nastartuje, ale nakonec instrumentální obohacování změnilo mě, přestala jsem na něj vyvíjet tlak, tlačit na pilu. Začala jsem se snažit chápat jeho samotného a jeho uvažování, což nakonec změnilo i moje dítě. Rodiče by měli být první, kdo instrumentálním obohacováním projde, i když instrumentální obohacování vyhledají „proto, aby moje dítě bylo normální“.',
  'Na kurzu jsem si uvědomila své fungování, jak přemýšlím, pochopení sama sebe, nejen klienta.',
  'Uvědomila jsem si důležitost toho, že instrumenty podrobně procházíme. Je důležité si to prožít, zažít.',
  'Dvoutýdenní instrumentální obohacování stoprocentně splnilo mé očekávání.',
  'Instrumentální obohacování nesplnilo moje očekávání, protože ze všech předchozích školení jsem si nic neodnesl, a to samé jsem tedy očekával od instrumentálního obohacování. Instrumentální obohacování ale bylo jiné. Doporučil bych, aby tímto kurzem prošel všechen personál Vězeňské služby ČR.',
  'Za pět dní jsem poznal něco, co jsem za celý dosavadní život nezažil (při průchodu různými školami s frontální výukou a časovými omezeními). Za necelý týden cítím, že potřebuji víc času. Kvůli setrvačnosti jsem ale dál uplatňoval i metody, o kterých jsem věděl, že jsou špatné. A teď jsem zjistil, že čas vůbec není podstatný.',
]

const textsSk = [
  'Silné uvedomenie: vnímať a rešpektovať názory, kde sa ten jednotlivec nachádza. Upevnilo to moju predstavu o tímovej práci, ktorú som si aj užila.',
  'Otázky a s tým spojené nehodnotenie...Otázka -- a s kľudom budem čakať na ich odpovede. Pre mňa je to zážitok ako učiteľky, že som chcela niečo nové zažiť a zažila som a teraz viem, čo je nehodnotiť. Predtým som si myslela, že nehodnotím a hodnotila som.',
  'Tu sme celý čas robili s chybou. Aj keď ja som chyboval, nemusí to byť zle.',
]

const Box = styled.div`
  ${({ theme: { colors } }) => css`
    background-color: ${colors.bgGrey};
    padding: 32px 34px 54px 20px;
    max-width: unset;
    width: 100%;
    position: relative;
    top: 50%;
    z-index: 10;
    margin-right: -10%;
    flex-shrink: 10;
    min-width: 0;
    ${up('mobile')} {
      padding: 82px 150px 116px 98px;
      min-width: 500px;
    }
    ${up(bgImageBp)} {
      max-width: 868px;
    }
  `}
`

const CircleWrapper = styled.div`
  padding: 6px;
`

const StyledDotGroup = styled(DotGroup)`
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);
  ${up('mobile')} {
    right: 42px;
  }
`

const Circle = styled.div`
  ${({ isActive, theme: { colors } }) => css`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${isActive ? colors.darkOrange : '#888888'};
    opacity: 0.76;
    transition: 0.3s;
    ${up('mobile')} {
      width: 10px;
      height: 10px;
    }
  `}
`

const BgImage = styled(Img)`
  width: 100%;
`

const BgImageWrapper = styled.div`
  width: 517px;
  max-width: 100%;
  flex-shrink: 0;

  display: none;
  ${up(bgImageBp)} {
    display: block;
  }
`

const QuotationWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 18px;
  transform: translateY(50%);
  img {
    width: 100%;
  }

  width: 100px;
  ${up('mobile')} {
    width: 224px;
    right: 20%;
  }
`

const shuffle = a => {
  let j
  let x
  let i
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1))
    x = a[i]
    a[i] = a[j]
    a[j] = x
  }
  return a
}

const getRandomTexts = (count = 3) => {
  const array = shuffle(isCs ? textsCs : textsSk)
  return array.slice(0, count)
}

// const texts = [
//   {
//     title: 'Snadné řešení situací',
//     content: (
//       <Text.Wide>
//         Z každého pondělního setkání{' '}
//         <Text.Strong>odcházím odpočinutá a plná energie</Text.Strong>, i když
//         kurzy končí pozdě večer. Celkem pravidelně se mi stává, že mě cestou
//         domů napadne <Text.Strong>jednoduché řešení nějaké situace</Text.Strong>
//         , se kterou jsem nemohla hnout.
//       </Text.Wide>
//     ),
//   },
// ]

const Testimonials = ({ data: { image } }) => {
  const randomTexts = getRandomTexts()
  return (
    <Row width="100%" position="relative" alignItems="center">
      <Box>
        <Title.MiniOrange as="h3">
          <T>reflexe účastníků</T>
        </Title.MiniOrange>
        <Gap gap="22px" />
        <Title as="h4">
          <T>Řekli o našich kurzech</T>
        </Title>
        <CarouselProvider
          naturalSlideWidth={400}
          naturalSlideHeight={190}
          totalSlides={3}
          isIntrinsicHeight
          isPlaying
          interval={5000}
        >
          <Slider>
            {randomTexts.map((content, key) => (
              <Slide index={key} key={key}>
                <Col width="100%">
                  <Gap gap="38px" />
                  <Text.Wide>{content}</Text.Wide>
                </Col>
              </Slide>
            ))}
          </Slider>
          <StyledDotGroup
            renderDots={({ currentSlide }) => (
              <Col>
                {randomTexts.map((p, key) => (
                  <Dot slide={key} key={key}>
                    <CircleWrapper>
                      <Circle isActive={currentSlide === key} />
                    </CircleWrapper>
                  </Dot>
                ))}
              </Col>
            )}
          />
        </CarouselProvider>
        <QuotationWrapper>
          <img src={quotationSrc} alt="Quotation" />
        </QuotationWrapper>
      </Box>
      <BgImageWrapper>
        <BgImage fluid={image.image.fluid} />
      </BgImageWrapper>
    </Row>
  )
}

const WithQuery = props => (
  <StaticQuery
    query={query}
    render={queryResult => <Testimonials {...props} data={queryResult} />}
  />
)

export default WithQuery

const query = graphql`
  query {
    image: file(relativePath: { eq: "images/testimonials3.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 517, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
