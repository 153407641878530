// external imports
import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../../components/SEO'
import { Text, MethodPageLayout, Button, Gap } from '../../components'
import { links } from '../../links'

class Feuerstein extends React.Component {
  componentDidMount() {}

  render() {
    const {
      location: { pathname },
      data: { image1, image2, image3 },
    } = this.props
    const images = [image1, image2, image3]

    return (
      <>
        <SEO
          title="Život Reuvena Feuersteina"
          pathname={pathname}
        />
        <MethodPageLayout>
          <MethodPageLayout.Title>
            Život Reuvena Feuersteina
          </MethodPageLayout.Title>
          <MethodPageLayout.TitleGap />
          <MethodPageLayout.SubTitle>Mládí a studia</MethodPageLayout.SubTitle>
          <MethodPageLayout.ParagraphGap />
          <MethodPageLayout.Content images={images}>
            <Text.Wide>
              Prof. Reuven Feuerstein se narodil v rumunském Botosani 21. srpna
              1921 do početné židovské rodiny. Jeho životní směřování bylo silně
              ovlivněno otcem – profesorem judaistiky a váženým poradcem
              židovské obce. Ten ve svých dětech pěstoval nejen náboženský
              pohled na svět, ale obecně filosofický, v němž schopnost
              kritického myšlení zaujímala důležitou roli. Zejména schopnost
              správně klást otázky se stala klíčovou v pozdější Feuersteinově
              výzkumné práci. V letech 1940–1944 studoval Feuerstein pedagogiku
              na univerzitě v Bukurešti. Jako mnoho jiných ani on neunikl
              zatčení gestapem a věznění v rumunském transitním táboře. V něm
              sestavil vlastní výukový plán, ujal se role učitele a tím, jak se
              později ukázalo, položil základy svému budoucímu výzkumu. Před
              koncem 2. světové války Feuerstein odcestoval do Haify, kde začal
              pracovat pro hnutí Youth Aliyah (organizace pomáhající s integrací
              holokaust přeživších dětí) a aktivně se zapojoval do tvorby nového
              státu Izrael. Feuerstein byl později díky Youth Aliyah poslán do
              Jeruzaléma, kde studoval na první zdejší pedagogické fakultě. Již
              v této době se ostře stavěl proti názoru, který vnímal proces
              učení jako pasivní přijímání informací coby nezpochybnitelných
              dogmat. V letech 1945–1948 vyučoval a zastával roli speciálního
              pedagoga v osadách pro děti a mládež postižené následky
              holokaustu.
            </Text.Wide>
            <MethodPageLayout.ParagraphGap />
            <MethodPageLayout.ParagraphGap />
            <MethodPageLayout.SubTitle>
              Další studium, profesní život
            </MethodPageLayout.SubTitle>
            <MethodPageLayout.ParagraphGap />
            <Text.Wide>
              Na přelomu 40. a 50. let se Feuerstein ještě psychologické práci
              intenzivně nevěnoval, životní okolnosti jej však zavedly do
              Švýcarska, kde nasměroval jeho pozornost ke studiu psychologie
              Carl Gustav Jung. Feuerstein se rozhodl pro studium kognitivní
              psychologie a odjel do Ženevy, kde se stal žákem Jeana Piageta.
              Piaget ve své době představoval revoluční objevy v oblasti
              fungování kognitivních funkcí a Feuesrtein se tak učil u
              nejlepšího z nejlepších a celý koncept struktury kognitivních
              funkcí od něj do značné míry převzal.
            </Text.Wide>
            <MethodPageLayout.ParagraphGap />
            <Text.Wide>
              Nebýt zkušeností, které Feuerstein získal při pozorování svých
              svěřenců, nestál by ovšem později v tak přímé opozici Piagetově
              tvrzení o kognitivním vývoji. Nemohl totiž přehlížet jasný důkaz
              toho, jak v poválečné Izraeli deprimované a frustrované děti v
              jeho citlivé interakci doslova rozkvétaly. Feuerstein se vymezoval
              vůči teorii, v níž je uspořádání a načasování kognitivního vývoje
              řízeno vnitřním, biologicky daným mechanismem zrání. Stál si za
              přesně opačným tvrzením, když říkal, že strukturu kognitivních
              funkcí a časovou posloupnost jejich vývoje určují vztahy s druhými
              lidmi, tzv.kulturní transmise. Definitivní rozchod Piageta–učitele
              a Feuersteina–žáka nastal v důsledku navázání Feuersteinovy
              výzkumné spolupráce s André Reyem, jehož náhled na utváření
              kognitivních struktur se s Feuersteinovým v mnohém shodoval. V
              letech 1950 – 1958 pracoval Feuerstein jako vedoucí psychologické
              sekce služeb Youth Aliyah v Evropě. Zásadní Feuersteinově a Reyově
              výzkumné práci s marockými dětmi, která probíhala v letech
              1950-1954, se věnuje studie nazvaná „Children of Mellah“. Ta
              představuje základ pozdějšího komplexního vědeckého zpracování
              teorie strukturní kognitivní modifikovatelnosti. Utváření
              výsledného stavu mediativní teorie spadá do období let 1954–1970.
              V této době vzniká nejen konečné definování dimenzí kognitivní
              mapy a dotvoření souboru kognitivně stimulačních materiálů pod
              názvem program Instrumentálního obohacování, ale i diagnostická
              testová baterie pro zjišťování potenciality učit se (LPAD).
            </Text.Wide>
            <MethodPageLayout.ParagraphGap />
            <Text.Wide>
              V roce 1970 Feuerstein obhájil doktorát na univerzitě v Sorboně. V
              průběhu 60. let 20. stol. se Feuersteinovy myšlenky šířily do
              Spojených států a Kanady. V Kanadě Feuerstein spolupracoval s
              výzkumným Hadassa-WIZO-Canada Research Institut, v němž měl ve své
              práci značnou podporu. Úkolem střediska ICELP (International
              Center for Enhancement of Learning Potential), které Feuerstein
              založil v roce 1993 bylo zejména zastřešit a podporovat jednotlivá
              tréninková centra, která jsou nyní ve více než 24 zemích světa, a
              která šíří víru v modifikovatelnost člověka prostřednictvím práce
              s Instrumentálním obohacováním. Celý život strávil Feuerstein
              výzkumem vývojové, klinické a kognitivní psychologie z pohledu,
              který respektuje kulturní odlišnosti. V roce 2012 byl za svou
              práci nominován na Nobelovu cenu míru.
            </Text.Wide>
            <MethodPageLayout.ParagraphGap />
            <Text fontSize="13px" lineHeight="20px" letterSpacing="0.26px">
              <strong>Zdroje:</strong> <br /> MÁLKOVÁ, Gabriela. Zprostředkované
              učení: jak učit žáky myslet a učit se. Praha: Portál, 2009. ISBN
              978-80-7367-585-1 FEUERSTEIN, Reuven. Vytváření a zvyšování
              kognitivní modifikovatelnosti: Feuersteinův program
              instrumentálního obohacení. Praha: Karolinum, 2014. ISBN
              978-80-246-2400-6. https://cs.wikipedia.org/wiki/Reuven_Feuerstein
              LEBEER, Jo, ed. Programy pro rozvoj myšlení dětí s odchylkami
              vývoje: podpora začleňování znevýhodněných dětí do běžného
              vzdělávání. Praha: Portál, 2006. Speciální pedagogika (Portál).
              ISBN 80-7367-103-4.
            </Text>
            <Gap gap="40px" />
            <Button.SecondaryOrange
              link={{ pageKey: links.METHODS }}
              iconLeft="arrowLeft"
            >
              Zpátky na rozcestník
            </Button.SecondaryOrange>
          </MethodPageLayout.Content>
        </MethodPageLayout>
      </>
    )
  }
}

export default Feuerstein

export const pageQuery = graphql`
  query {
    image1: file(relativePath: { eq: "images/methods/feuerstein1.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 452, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image2: file(relativePath: { eq: "images/methods/feuerstein2.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 452, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image3: file(relativePath: { eq: "images/methods/feuerstein3.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 452, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
