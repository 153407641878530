import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import {
  addResponsivity,
  up,
  down,
  between,
  addFont,
  colors,
} from '../../lib/styles'
import { Col, Row, Text, Title, Gap, Link, VH, Button } from '..'

const Label = styled.label`
  ${({ theme: { colors }, smaller, error }) => css`
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;

    ${smaller &&
      css`
        font-size: 13px;
        line-height: 20px;
      `}

    ${error &&
      css`
        color: ${colors.red};
      `}
  `}
`

export default Label
