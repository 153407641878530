import React from 'react'
import injectIntl from './injectIntl'

const T = ({
  children,
  plainText,
  args = [],
  raw,
  intl: { t, language },
  ...props
}) => {
  let translated = t(children, language)

  args.forEach((arg, index) => {
    translated = translated.split(`{arg${index + 1}}`).join(arg)
  })

  if (plainText) {
    return translated
  }

  if (!raw) {
    return <>{translated}</>
  }

  return <span dangerouslySetInnerHTML={{ __html: translated }} />
}

export default injectIntl(T)
