import React from 'react'
import { createGlobalStyle, ThemeProvider, css } from 'styled-components'
import styledNormalize from 'styled-normalize'
import { theme } from '../lib/styles'
import fontFiles from '../lib/fonts'
import superFormReset from '../lib/superFormReset'
import '../lib/fragments' // has to be imported somewhere to gatsby notice that
import IntlProvider from '../lib/intl/IntlProvider'

const GlobalStyle = createGlobalStyle`${({ theme: { colors } }) => css`
  ${superFormReset}
  ${styledNormalize}
  ${fonts}

  html {
    /* scroll-behavior: smooth; */
  }

  body {
    background: white;
    font-family: 'Noto Sans', sans-serif;
    color: ${colors.text};
  }
  * {
    box-sizing: border-box;
  }
  p {
    margin: 0;
    padding: 0;
  }
  a {
    text-decoration: none;
    color: inherit;
    /* display: inline-block;
    color: ${colors.green};
    &:hover {
      color: ${colors.darkGreen};
    } */
  }
`}`

// eslint-disable-next-line react/prefer-stateless-function
class App extends React.Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyle />
          <IntlProvider intl={this.props.pageContext}>
            {this.props.children}
          </IntlProvider>
        </>
      </ThemeProvider>
    )
  }
}

export default App

const fonts = css`
  @font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('Noto Sans'), url(${fontFiles.NotoSansLight}) format('truetype');
  }
  @font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('Noto Sans'),
      url(${fontFiles.NotoSansRegular}) format('truetype');
  }
  @font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 600;
    src: local('Noto Sans'), url(${fontFiles.NotoSansBold}) format('truetype');
  }
  @font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('Noto Sans'),
      url(${fontFiles.NotoSansExtraBold}) format('truetype');
  }
  @font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 100;
    src: local('Noto Sans'),
      url(${fontFiles.NotoSansExtraLight}) format('truetype');
  }
`
