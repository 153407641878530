import React from 'react'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'
import { addResponsivity, up, down, between } from '../lib/styles'

const ResponsiveImage = styled(Img)`
  ${addResponsivity()}
`

export default ResponsiveImage
